import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import useSWR from "swr";
import { UpdateBillingRequest } from "../@types/bill";
import BaseInput from "../components/Base/BaseInput";
import { billService } from "../services/bill";
import { requestAuthUser } from "../store/auth/auth.actions";

const BillingForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { data: checkUnset } = useSWR("/v1/billing/check-unset-billing", billService.unsetBilling);
    const billingResponse = useFormik<UpdateBillingRequest>({
        initialValues: {
            address: "",
            company: "",
            tax_id: ""
        },
        onSubmit: async (values) => {
            try {
                const response = await billService.updateBilling(values);

                dispatch(requestAuthUser());
                history.push("/billing");
                toast.success(response.message);
            } catch ({ message }: any) {
                toast.error(message as string);
            }
        }
    });

    useEffect(() => {
        dispatch(requestAuthUser());
        if (!checkUnset?.status) {
            history.push("/billing/history");
        }
    }, [checkUnset, dispatch, history]);

    return (
        <div className="block">
            <div className="header-with-element">
                <h2 className="mb-0">Billing</h2>
            </div>
            <div className="xver-content">
                <div className="billing-form columns">
                    <div className="column col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="card-title h5">
                                    Billing Info Settings
                                </div>
                                <div className="card-subtitle text-gray">
                                    ตั้งค่ารายละเอียดการเรียกเก็บเงิน
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="content">
                                    <div className="billing-text-detail text-warning">
                                        <strong>
                                        <i className="fas fa-engine-warning"></i>&nbsp; คุณจำเป็นต้องกรอกรายละเอียดการเรียกเก็บเงินของคุณ ก่อนที่จะเริ่มใช้งาน
                                        </strong>
                                    </div>
                                    <form autoComplete="off" onSubmit={billingResponse.handleSubmit}>
                                        <div className="columns">
                                            <div className="column col-12">
                                                <BaseInput
                                                    type="text"
                                                    name="company"
                                                    label="ชื่อบริษัทหรือองค์กรของคุณ (หากมี)"
                                                    maxLength={64}
                                                    onChange={billingResponse.handleChange}
                                                    value={billingResponse.values.company}
                                                />
                                            </div>
                                            <div className="column col-12">
                                                <BaseInput
                                                    type="text"
                                                    name="tax_id"
                                                    label="เลขประจำตัวของผู้เสียภาษี"
                                                    maxLength={64}
                                                    required
                                                    onChange={billingResponse.handleChange}
                                                    value={billingResponse.values.tax_id}
                                                />
                                            </div>
                                            <div className="column col-12">
                                            <BaseInput
                                                type="text"
                                                name="address"
                                                label="ที่อยู่ของผู้เสียภาษี"
                                                required
                                                onChange={billingResponse.handleChange}
                                                value={billingResponse.values.address}
                                            />
                                            </div>
                                            <div className="column col-12">
                                            <button type="submit" className="col-12 btn btn-primary btn-billing-form">บันทึกรายละเอียด</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BillingForm;
