import React from "react";
import { Notify } from "src/@types/notify";

interface NotificationBoxProps {
    notify: Notify;
}

export default function NotificationBox(props: NotificationBoxProps): Readonly<React.ReactElement> {
    return (
        <div className="xver-box">
            <div className="box-content">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'start'
                    }}
                >
                    <span
                        style={{
                            width: '100%',
                            fontWeight: 500,
                            color: props.notify.level === "typical" ? "#3b82f6" : props.notify.level === "warning" ? "#dca105" : "#ef4444"
                        }}
                    >
                        <i className="fas fa-exclamation-circle" />
                        &nbsp;{props.notify.title}
                    </span>
                    <span>
                        {props.notify.content}
                    </span>
                    {props.notify?.button}
                </div>
            </div>
        </div>
    );
}
