import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { setAuthUser } from '../store/auth/auth.actions'
import { reset } from 'src/services/chatwoot'

const SignOut = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        try {
            reset()
        } catch (error) {
            console.error(error)
        }

        localStorage.removeItem('x-auth')

        dispatch(setAuthUser(undefined))
        history.push('/sign-in')
    }, [history, dispatch])
    return null
}

export default SignOut
