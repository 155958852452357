import { useFormik } from 'formik'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import BaseAlert from '../components/Base/BaseAlert'
import BaseButton from '../components/Base/BaseButton'
import BaseInput from '../components/Base/BaseInput'
import { authService } from '../services/auth'

interface ConfirmResetPasswordParams {
	userID: string
}

const ConfirmResetPassword = () => {
	const params = useParams<ConfirmResetPasswordParams>()
	const userID = parseInt(params.userID)

	const history = useHistory()
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			otpCode: '',
			password: '',
		},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const res = await authService.confirmResetPassword(userID, values)

				toast.info(res.message)
				history.push('/sign-in')
			} catch ({ message }) {
				setSubmitting(false)

				toast.error(message as string)
			}
		},
	})

	return (
		<form autoComplete="off" onSubmit={handleSubmit}>
			<BaseAlert warning="กรุณากรอกข้อมูลให้ถูกต้องเพื่อรีเซ็ตรหัสผ่าน" />
			<BaseInput type="text" name="otpCode" label="รหัสยืนยันจากโทรศัพท์ (OTP)" required onChange={handleChange} />
			<BaseInput type="text" name="password" label="รหัสผ่านใหม่" required onChange={handleChange} />

			<div className="form-group">
				<BaseButton type="submit" color="primary" classes="mr-2" loading={isSubmitting}>
					ยืนยัน
				</BaseButton>
				<Link to="/sign-in" className="btn btn-default">
					ย้อนกลับ
				</Link>
			</div>
		</form>
	)
}

export default ConfirmResetPassword
