import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import useSWR from 'swr'
import { LaunchInstanceData } from '../@types/launch'
import AuthSetting from '../components/Deploy/AuthSetting'
import SelectOS from '../components/Deploy/SelectOS'
import SelectPackage from '../components/Deploy/SelectPackage'
import ServerSetting from '../components/Deploy/ServerSetting'
import { instanceService } from '../services/instance'
import { sshKeyService } from '../services/sshKey'
import { requestAuthUser } from '../store/auth/auth.actions'
import { randomString } from '../utils/string'

const DeployNewCloudServer = () => {
	const { data: launchOptions } = useSWR('/v1/launch', instanceService.getLaunchOptions)
	const { data: sshKeys } = useSWR('/v1/ssh-keys', sshKeyService.getSSHKeys)

	const dispatch = useDispatch()
	const history = useHistory()
	const { values, isSubmitting, setFieldValue, handleChange, handleSubmit } = useFormik<LaunchInstanceData>({
		initialValues: {
			os: 'windows-server',
			image: 'win-2016',
			pkg: 6,

			hostname: `xver-${randomString(6)}`,
			keys: [],
			enableStartupScript: false,
			startupScript: '',
		},
		onSubmit: async (values) => {
			if (values.os === '' || values.image === '' || values.image === 'เลือกเวอร์ชั่น') {
				toast.error('กรุณาเลือกเวอร์ชั่นให้ถูกต้อง')
				return false
			}

			try {
				const res = await instanceService.launchInstance(values)

				dispatch(requestAuthUser())
				history.push('/cloud-servers')
				toast.success(res.message)
			} catch ({ message }: string | any) {
				toast.error(message as string)
			}
		},
	})
	const selectKey = (content: string) => {
		if (values.keys.includes(content)) {
			setFieldValue(
				'keys',
				values.keys.filter((v) => v !== content)
			)
		} else {
			values.keys.push(content)
			setFieldValue('keys', values.keys)
		}
	}

	const defaultUsername = values.os !== 'windows-server' ? 'xver' : 'Administrator'
	const enableSSHKey = ['ubuntu', 'centos', 'debian'].includes(values.os)
	const enableStartupScript = ['ubuntu', 'centos', 'debian'].includes(values.os)

	return (
		<div className="block">
			<div className="header-with-element">
				<div>
					<h2 className="mb-0">
                        <i className="fas fa-cloud" />
                        &nbsp;สร้างคลาวด์เซิร์ฟเวอร์ใหม่
                    </h2>
				</div>

				<Link to="/cloud-servers" className="btn btn-primary btn-action">
					<i className="fas fa-arrow-left" />
				</Link>
			</div>
			{launchOptions && sshKeys ? (
				<div className="xver-content">
					<SelectOS
						data={launchOptions.os}
						selected={values.image}
						select={(os: string, image: string) => {
							setFieldValue('os', os)
							setFieldValue('image', image)
							setFieldValue('keys', [])

							setFieldValue('enableStartupScript', false)
							setFieldValue('startupScript', '')
						}}
					/>
					{/* Windows Server license warning */}
					{values.os === 'windows-server' && (
						<div className="toast toast-default">
							<i className="fad fa-exclamation-circle" /> No Windows License included.
						</div>
					)}

					<div className="mt-1">
						<SelectPackage
							data={launchOptions.packages}
							isWindowsServer={values.os === 'windows-server'}
							selected={values.pkg}
							select={(v: number) => setFieldValue('pkg', v)}
						/>
					</div>
					<div className="columns mt-1">
						<div className="column col-6 col-sm-12">
							<div className="xver-box">
								<div className="box-header">
									<h2>Authentication</h2>
									<h6>ตั้งค่าการเข้าสู่ระบบ</h6>
								</div>
								<div className="box-content">
									<AuthSetting
										username={defaultUsername}
										enableSSHKey={enableSSHKey}
										sshKeys={sshKeys}
										selectKey={selectKey}
										handleChange={handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="column col-6 col-sm-12">
							<div className="xver-box">
								<div className="box-header">
									<h2>Server Setting</h2>
									<h6>ตั้งค่าเซิร์ฟเวอร์</h6>
								</div>
								<div className="box-content">
									<ServerSetting
										loading={isSubmitting}
										hostname={values.hostname}
										enableStartupScript={enableStartupScript}
										startupScriptChecked={values.enableStartupScript}
										setFieldValue={setFieldValue}
										handleChange={handleChange}
										handleSubmit={handleSubmit}
									/>
								</div>
							</div>
							<div style={{
								color: "yellow"
							}}
								className="text-warning text-small"
							>
								<i className="fad fa-engine-warning text-warning" />
								&nbsp; คิดค่าบริการต่อชั่วโมง ราคาต่อเดือนเป็นการประมาณค่าใช้จ่ายเมื่อใช้บริการครบเดือน
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="loading xver-loading" />
			)}
		</div>
	)
}

export default DeployNewCloudServer
