import { useFormik } from 'formik'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import BaseButton from '../components/Base/BaseButton'
import BaseInput from '../components/Base/BaseInput'
import { authService } from '../services/auth'
import { useEffect, useState } from 'react'
import Promotion from 'src/components/Promotion/Promotion'
import { reset } from 'src/services/chatwoot'

const SignIn = () => {
    const history = useHistory()

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const coupon = queryParams.get('code') ?? ''

    const [couponStored, setCouponStored] = useState<boolean>(
        localStorage.getItem('coupon') ? true : false
    )
    const [couponCode, setCouponCode] = useState<string | null>(
        localStorage.getItem('coupon') ? localStorage.getItem('coupon') : ''
    )
    const [couponValue, setCouponValue] = useState<string | null>(
        localStorage.getItem('coupon_value')
            ? localStorage.getItem('coupon_value')
            : ''
    )

    const { isSubmitting, handleChange, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const res = await authService.signIn(values)

                if (res.data?.token) {
                    localStorage.setItem('x-auth', res.data.token)
                    history.push('/cloud-servers')
                } else if (res.data) {
                    if (res.data?.phoneNumber) {
                        toast.info(res.message)

                        const { phoneNumber, verificationToken } = res.data
                        history.push(
                            `/verify/phone?phone=${phoneNumber}&t=${verificationToken}`
                        )
                    } else if (res.data.verificationLevel) {
                        localStorage.setItem(
                            'x-auth',
                            res.data.verificationToken
                        )
                        history.push('/cloud-servers')
                    } else {
                        toast.info(res.message)

                        const {
                            emailAddress,
                            verificationLevel,
                            verificationToken,
                        } = res.data
                        history.push(
                            `/verify/email-address?email=${emailAddress}&level=${verificationLevel}&token=${verificationToken}`
                        )
                    }
                }
                reset()
            } catch ({ message }: any) {
                setSubmitting(false)

                toast.error(message as string)
            }
        },
    })

    useEffect(() => {
        if (coupon) {
            const deccoded = Buffer.from(String(coupon), 'base64').toString()
            const splited = deccoded.split('|')

            if (/^[0-9]+$/.test(splited[1])) {
                localStorage.setItem('coupon', splited[0])
                localStorage.setItem('coupon_value', splited[1])

                setCouponCode(splited[0])
                setCouponValue(splited[1])
                setCouponStored(true)
            }
        }
    }, [coupon])

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            <BaseInput
                type="email"
                name="email"
                label="อีเมล"
                required
                onChange={handleChange}
            />
            <BaseInput
                type="password"
                name="password"
                label="รหัสผ่าน"
                required
                onChange={handleChange}
            />

            {couponStored && (
                <Promotion coupon={couponCode} couponValue={couponValue} />
            )}

            <div className="form-group auth-submit-group">
                <BaseButton
                    type="submit"
                    color="primary"
                    classes="mr-2"
                    loading={isSubmitting}
                >
                    เข้าสู่ระบบ
                </BaseButton>
                <Link to="/reset-password">ลืมรหัสผ่าน?</Link>
            </div>
        </form>
    )
}

export default SignIn
