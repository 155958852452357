import React from 'react';

import qs from 'querystring';

import { useHistory } from 'react-router-dom';
import { authService } from 'src/services/auth';
import { toast } from 'react-toastify';
import { Text } from '@geist-ui/core';

const ApproveVerifyEmailAddress: React.FC = () => {
    const q = qs.parse(window.location.search.replace('?', ''))
    const ref = q.ref as string
    const token = q.token as string

    const history = useHistory()

    if (!ref || !token) {
        history.push('/sign-in');
    }

    React.useEffect(() => {
        (async () => {
            try {
                const response = await authService.verifyEmailAddressByRef(ref, token)

                if (response?.token) {
                    toast.success("ยืนยันตัวตนสำเร็จ, กำลังนำเข้าสู่ระบบ!");

                    localStorage.setItem('x-auth', response.token);
					history.push('/cloud-servers')

                }
            } catch ({ message }: any) {
                toast.error(message as string);

                history.push('/sign-in');
            }
        })();
    }, [history, ref, token]);

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'nowrap'
            }}
        >
            <Text type='success' h4>กำลังเช็คสถานะการยืนยันตัวตน...</Text>
        </div>
    );
};

export default ApproveVerifyEmailAddress;
