import clsx from 'clsx'
import React, { FC } from 'react'
import { BaremetalInstance, Instance } from '../../@types/instance'
import BaseButton from '../Base/BaseButton'

interface DestroyConfirmationModalProps {
	loading: boolean
	data?: Instance | BaremetalInstance

	onConfirm: () => void
	onClose: () => void
}
const DestroyConfirmationModal: FC<DestroyConfirmationModalProps> = ({
	loading,
	data,

	onConfirm,
	onClose,
}) => (
	<div className={clsx('modal modal-sm', { active: data })}>
		<span className="modal-overlay" onClick={onClose} />

		<div className="modal-container">
			<div className="modal-header">
				<button className="btn btn-clear float-right" onClick={onClose} />
				<div className="modal-title h5">
					<i className="fad fa-engine-warning text-warning" />
					&nbsp; คุณต้องการลบเซิร์ฟเวอร์?
				</div>
			</div>
			<div className="modal-body">
				<div className="content">
					ข้อมูลทั้งหมดจะถูกลบและไม่สามารถกู้คืนได้ กรุณาตรวจสอบข้อมูลก่อนดำเนินการลบเซิร์ฟเวอร์
				</div>
			</div>
			<div className="modal-footer">
				<BaseButton type="button" color="primary" loading={loading} onClick={onConfirm}>
					ยืนยัน
				</BaseButton>
				<button type="button" className="btn btn-default" onClick={onClose}>
					ยกเลิก
				</button>
			</div>
		</div>
	</div>
)

export default DestroyConfirmationModal
