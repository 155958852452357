import { FC } from 'react'
import CentosIcon from '../../assets/image/centos.svg'
import DebianIcon from '../../assets/image/debian.svg'
import UbuntuIcon from '../../assets/image/ubuntu.svg'
import WindowsServerIcon from '../../assets/image/windows-server.svg'

interface OSIconProps {
	slug: string
}
const OSIcon: FC<OSIconProps> = ({ slug }) => {
	let icon = ''
	switch (slug) {
		case 'windows-server': {
			icon = WindowsServerIcon
			break
		}
		case 'ubuntu': {
			icon = UbuntuIcon
			break
		}
		case 'centos': {
			icon = CentosIcon
			break
		}
		case 'debian': {
			icon = DebianIcon
			break
		}
	}

	return <img src={icon} alt={slug} />
}

export default OSIcon
