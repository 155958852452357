import React from 'react'

const TermOfService = () => (
    <div className='auth-body'>
        <div className='container'>
            <div className='columns'>
                <div className='column col-5 col-md-12 col-mx-auto mt-2'>
                    <div className='card'>
                        <div className='card-body'>
                            <h3 className='text-center'>ติดต่อเรา</h3>

                            <p>support@xver.cloud</p>
                            <p>0637533622</p>
                            <p>
                                {' '}
                                บ้าน เลขที่ 41/1 หมู่ 1 ต.เขาตอก อ.เคียนซา จ.
                                สุราษฎร์ธานี 84260
                            </p>
                            <p>https://www.facebook.com/xver.cloud</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default TermOfService
