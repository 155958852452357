import React from 'react'

const TermOfService = () => (
    <div className='auth-body'>
        <div className='container'>
            <div className='columns'>
                <div className='column col-5 col-md-12 col-mx-auto mt-2'>
                    <div className='card'>
                        <div className='card-body'>
                            <h3 className='text-center'>
                                นโยบายความเป็นส่วนตัว
                            </h3>

                            <p>
                                <strong>การเก็บรวบรวมข้อมูลส่วนบุคคล</strong>
                            </p>

                            <p>
                                1.
                                เพื่อความสะดวกในการให้บริการแก่ผู้ใช้บริการทุกท่านที่เข้ามาใช้บริการเว็บไซต์ของ
                                xver.cloud
                                ทางเว็บไซต์จึงได้จัดเก็บรวบรวมข้อมูลส่วนบุคคลของท่านไว้เช่น
                                อีเมล์แอดเดรส (Email Address) ชื่อ (Name)
                                หรือหมายเลขโทรศัพท์ (Telephone Number) เป็นต้น
                            </p>

                            <p>
                                3. นอกจากนั้น เพื่อสำรวจความนิยมในการใช้บริการ
                                อันจะเป็นประโยชน์ในการนําสถิติไปใช้ในการปรับปรุงคุณภาพในการให้บริการของ
                                xver.cloud
                                จึงจำเป็นต้องจัดเก็บรวบรวมข้อมูลของท่านบางอย่างเพิ่มเติม
                                ได้แก่ หมายเลขไอพี (IP Address) ชนิดของโปรแกรม
                                ค้นผ่าน (Browser Type)โดเมนเนม (Domain Name)
                                บันทึกหน้าเว็บ (web page)
                                ของเว็บไซต์ที่ผู้ใช้เยี่ยมชม
                                เวลาที่เยี่ยมชมเว็บไซต์(Access Times)
                                และเว็บไซต์ที่ผู้ใช้บริการเข้าถึงก่อนหน้านั้น
                                (Referring Website Addresses)
                            </p>

                            <p>
                                <strong>
                                    วัตถุประสงค์หรือเหตุผลที่ร้านค้าเก็บข้อมูล
                                </strong>
                            </p>

                            <p>
                                1. xver.cloud
                                จะใช้ข้อมูลส่วนบุคคลของท่านเพียงเท่าที่จำเป็น
                                เช่น ชื่อ และ ที่อยู่ เพื่อใช้ในการติดต่อ
                                ให้บริการ หรือให้ข้อมูลข่าวสารต่างๆ เท่านั้น
                            </p>

                            <p>
                                2. xver.cloud
                                ขอรับรองว่าจะไม่นําข้อมูลส่วนบุคคลของท่านที่
                                xver.cloud
                                ได้เก็บรวบรวมไว้ไปขายหรือเผยแพร่ให้กับบุคคลภายนอกโดยเด็ดขาด
                                เว้นแต่จะได้รับ อนุญาตจากท่านเท่านั้น&nbsp;
                            </p>

                            <p>
                                <strong>
                                    สิทธิในการควบคุมข้อมูลส่วนบุคคลของท่าน&nbsp;
                                </strong>
                            </p>

                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เพื่อประโยชน์ในการรักษาความเป็นส่วนตัวของท่าน
                                ท่านมีสิทธิเลือกที่จะให้มีการใช้หรือแชร์ข้อมูลส่วน
                                บุคคลของท่าน
                                หรืออาจเลือกที่จะไม่รับข้อมูลหรือสื่อทางการตลาดใดๆ
                                จาก xver.cloud ก็
                                ได้โดยเพียงแต่ท่านกรอกความจำนงดังกล่าว
                                เพื่อแจ้งให้ xver.cloud ทราบ
                            </p>

                            <p>
                                <strong>ระยะเวลาในการเก็บรักษาข้อมูล</strong>
                            </p>

                            <p>ตลอดอายุการใช้ของท่าน</p>

                            <p>
                                <strong>
                                    ช่องทางการติดต่อร้านค้าในกรณีที่ลูกค้าต้องการลบ
                                    แก้ไขข้อมูลส่วนบุคคลที่ร้านค้าจัดเก็บ
                                </strong>
                            </p>
                            <p>เบอร์ 0637533622</p>

                            <p>E-Mail support@xver.cloud</p>

                            <p>
                                บ้าน เลขที่ 41/1 หมู่ 1 ต.เขาตอก อ.เคียนซา จ.
                                สุราษฎร์ธานี 84260
                            </p>

                            <h3 className='text-center'>
                                นโยบายการคุ้มครองข้อมูล
                            </h3>

                            <p>
                                <strong>
                                    การรักษาความปลอดภัยสำหรับข้อมูลส่วนบุคคล&nbsp;
                                </strong>
                            </p>

                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เพื่อประโยชน์ในการรักษาความลับและความปลอดภัยสำหรับข้อมูลส่วนบุคคลของท่าน
                                xver.cloud
                                จึงได้กำหนดระเบียบเพื่อกำหนดสิทธิในการเข้าถึง
                                หรือใช้ข้อมูลส่วน บุคคลของท่าน
                                และเพื่อรักษาความลับและความปลอดภัยของข้อมูลบางอย่างที่มีความสำคัญอย่างยิ่ง
                                เช่น หมายเลขบัตรเครดิต เป็นต้น xver.cloud
                                จึงได้จัดให้มีช่องทางการสื่อสารแบบปลอดภัย
                                สำหรับข้อมูลดังกล่าวด้วยการเข้ารหัสลับข้อมูลดังกล่าว
                                เช่น จัดให้มีการใช้ Secure Socket Layer (SSL)
                                protocol เป็นต้น
                            </p>

                            <p>
                                <strong>การใช้คุกกี้ (Cookies)&nbsp;</strong>
                            </p>

                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“คุกกี้” คือ
                                ข้อมูลที่เว็บไซต์ xver.cloud
                                ส่งไปยังโปรแกรมค้นผ่านเว็บไซต์ (Web browser)
                                ของผู้ใช้บริการ
                                และเมื่อมีการติดตั้งข้อมูลดังกล่าวไว้ในระบบของท่านแล้ว
                                หากมีการใช้ “คุกกี้” ก็ จะทำให้เว็บไซต์
                                xver.cloud
                                สามารถบันทึกหรือจดจำข้อมูลของผู้ใช้บริการไว้จนกว่าผู้ใช้
                                บริการจะออกจากโปรแกรมค้นผ่านเว็บไซต์หรือจนกว่าผู้ใช้บริการจะทำการลบ
                                “คุกกี้” นั้นเสีย หรือไม่ อนุญาตให้ “คุกกี้”
                                นั้น ทำงานอีกต่อไป หากท่านเลือกใช้ “คุกกี้” แล้ว
                                ท่านจะได้รับความสะดวกสบายใน
                                การท่องเว็บไซต์มากขึ้น เพราะ “คุกกี้”
                                จะช่วยจดจำเว็บไซต์ที่ท่านแวะหรือเยี่ยมชม
                                ทั้งนี้เว็บไซต์ xver.cloud จะนําข้อมูลที่
                                “คุกกี้”
                                ได้บันทึกหรือเก็บรวบรวมไว้ไปใช้ในการวิเคราะห์เชิงสถิติหรือ
                                กิจกรรมอื่นของ xver.cloud
                                เพื่อปรับปรุงคุณภาพการให้บริการของ xver.cloud
                                ต่อไป
                            </p>

                            <p>
                                <strong>
                                    การปรับปรุงนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                                </strong>
                            </p>

                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xver.cloud
                                อาจทำการปรับปรุงหรือแก้ไขนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                                โดยมิได้แจ้งให้ท่านทราบล่วงหน้า
                                ทั้งนี้เพื่อความเหมาะสมและมีประสิทธิภาพในการให้บริการ
                                ดังนั้น xver.cloud
                                จึงขอแนะนําให้ผู้ใช้บริการอ่านนโยบายการคุ้มครองข้อมูลส่วนบุคคลทุกครั้งที่
                                เยี่ยมชม หรือมีการใช้บริการจากเว็บไซต์ของ
                                xver.cloud
                            </p>

                            <p>
                                <strong>
                                    การปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลและการติดต่อกับ
                                    xver.cloud
                                </strong>
                            </p>

                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่ท่านมีข้อสงสัย
                                ข้อเสนอแนะ หรือข้อติชมใดๆ
                                เกี่ยวกับนโยบายการคุ้มครองข้อมูลส่วนบุคคล
                                หรือการปฏิบัติตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
                                xver.cloud ยินดีที่จะ ตอบข้อสงสัย
                                รับฟังข้อเสนอแนะ และคําติชมทั้งหลาย
                                อันจะเป็นประโยชน์ต่อการปรับปรุงการให้บริการของ
                                xver.cloud ต่อไป โดยท่านสามารถติดต่อกับ
                                xver.cloud ที่ เบอร์&nbsp;
                            </p>

                            <p>เบอร์ 0637533622</p>

                            <p>E-Mail support@xver.cloud</p>

                            <p>
                                บ้าน เลขที่ 41/1 หมู่ 1 ต.เขาตอก อ.เคียนซา จ.
                                สุราษฎร์ธานี 84260
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default TermOfService
