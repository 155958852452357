import React, { FC } from 'react'
import { Link, RouterProps } from 'react-router-dom'
import logo from '../../assets/image/xver-v3-full.png'
import DebugBar from '../Debug/DebugBar'

const AuthLayout =
    (Component: React.ComponentType<object>): FC<RouterProps> =>
    (props: RouterProps) =>
        (
            <div className="auth-body">
                <DebugBar />
                <div className="container">
                    <div className="columns">
                        <div
                            className="column col-4 col-md-12 col-mx-auto auth-section"
                            style={{
                                paddingBottom: '32px',
                            }}
                        >
                            <div className="card">
                                <div className="card-body">
                                    <div
                                        className="text-center"
                                        style={{
                                            marginTop: '12px',
                                        }}
                                    >
                                        <a href="http://xver.cloud/">
                                            <img
                                                src={logo}
                                                alt=""
                                                className="logo-dark"
                                                height="30"
                                            />
                                        </a>
                                        <p className="text-muted mt-1">
                                            “the best solution for your
                                            business”
                                        </p>
                                    </div>
                                    <Component {...props} />
                                </div>
                            </div>
                            {props.history.location.pathname === '/sign-in' && (
                                <div className="auth-ext">
                                    ยังไม่มีบัญชีผู้ใช้งาน?{' '}
                                    <Link to="/sign-up">สมัครสมาชิก</Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )

export default AuthLayout
