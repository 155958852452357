import React from "react";

interface PromotionProps {
    coupon: string | null;
    couponValue: string | null;
}

export default function Promotion(props: PromotionProps): Readonly<React.ReactElement<PromotionProps>> {
    return (
        <div
            style={{
                margin: "0 0 20px 0",
                padding: '20px',
                border: 'dashed 1px #c4c4c4',
                borderRadius: '6px',
                backgroundColor: 'transparent'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'nowrap'
                }}
            >
                {/* <i className="fas fa-ticket-alt" /> */}
                <h5 style={{ color: '#7c7c7c' }}>คุณได้รับคูปองสำหรับเครดิตพิเศษ</h5>
                <h2 style={{
                    margin: "10px 0",
                    fontWeight: 700,
                    color: '#2372f5'
                }}>
                    {props.coupon}
                </h2>
                <span style={{
                    margin: 0,
                    fontSize: 20,
                    color: '#1469f4'
                    }}
                >
                        คูปองดังกล่าวมีมูลค่า {props.couponValue} บาท
                    </span>
            </div>
        </div>
    );
}
