import React, { ChangeEvent, FC } from 'react'
import { SSHKey } from '../../@types/sshKey'
import BaseInput from '../Base/BaseInput'

interface AuthSettingProps {
	username: string
	enableSSHKey: boolean
	sshKeys: SSHKey[]

	selectKey: (content: string) => void
	handleChange: (e: ChangeEvent<any>) => void
}
const AuthSetting: FC<AuthSettingProps> = ({
	username,
	enableSSHKey,
	sshKeys,
	selectKey,
	handleChange,
}) => {
	return (
		<div className="auth-setting">
			<BaseInput type="text" label="Username" value={username} disabled />

			{enableSSHKey && sshKeys.length > 0 && (
				<div className="form-group">
					<label className="form-label">SSH Keys</label>
					{sshKeys.map((v) => (
						<label className="form-checkbox" key={v.id}>
							<input
								type="checkbox"
								value={v.content}
								onChange={(e) => selectKey(e.currentTarget.value)}
							/>
							<i className="form-icon" /> {v.name}
						</label>
					))}
				</div>
			)}
		</div>
	)
}

export default AuthSetting
