import { Omise } from "omise-js-typed";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import { GeistProvider } from "@geist-ui/core";
import "./app.scss";
import AuthLayout from "./components/Layout/AuthLayout";
import store from "./store";
import ConfirmResetPassword from "./views/ConfirmResetPassword";
import ResetPassword from "./views/ResetPassword";
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";
import TermOfService from "./views/TermOfService";
import VerifyPhone from "./views/VerifyPhone";

import RefundPolicy from "./views/RefundPolicy";
import PrivacyAndDataProtectionPolicy from "./views/PrivacyAndDataProtectionPolicy";
import ContactUs from "./views/ContactUs";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import VerifyEmailAddress from "./views/VerifyEmailAddress";
import ApproveVerifyEmailAddress from "./views/ApproveVerifyEmailAddress";
import ResetEmailAddress from "./views/ResetEmailAddress";

ReactGA.initialize("UA-124245278-1");
ReactGA.pageview(window.location.pathname + window.location.search);

declare global {
    interface Window {
        Omise: Omise;
    }
}

window.Omise.setPublicKey(
    process.env.REACT_APP_OMISE_PKEY || "pkey_test_5cq6bsnexpcagdh5m6o"
);
window.OmiseCard.configure({
    publicKey:
        process.env.REACT_APP_OMISE_PKEY || "pkey_test_5cq6bsnexpcagdh5m6o",
});
dayjs.extend(localizedFormat);
ReactDOM.render(
    <React.StrictMode>
        <GeistProvider>
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route
                            exact
                            path="/sign-in"
                            component={AuthLayout(SignIn)}
                        />
                        <Route
                            exact
                            path="/sign-up"
                            component={AuthLayout(SignUp)}
                        />
                        <Route
                            exact
                            path="/reset-password"
                            component={AuthLayout(ResetPassword)}
                        />
                        <Route
                            exact
                            path="/reset-password/:userID"
                            component={AuthLayout(ConfirmResetPassword)}
                        />
                        <Route
                            exact
                            path={"/reset-email-address"}
                            component={AuthLayout(ResetEmailAddress)}
                        />
                        <Route
                            exact
                            path="/verify/phone"
                            component={AuthLayout(VerifyPhone)}
                        />
                        <Route
                            exact
                            path={"/verify/email-address"}
                            component={AuthLayout(VerifyEmailAddress)}
                        />
                        <Route
                            exact
                            path={`/verify/email-address/approve`}
                            component={AuthLayout(ApproveVerifyEmailAddress)}
                        />
                        <Route
                            exact
                            path="/term-of-service"
                            component={TermOfService}
                        />
                        <Route
                            exact
                            path="/refund-policy"
                            component={RefundPolicy}
                        />
                        <Route
                            exact
                            path="/privacy-and-data-protection-policy"
                            component={PrivacyAndDataProtectionPolicy}
                        />
                        <Route exact path="/contact-us" component={ContactUs} />

                        <App />
                    </Switch>
                </Router>

                <ToastContainer />
            </Provider>
        </GeistProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
