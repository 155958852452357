import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Notify } from "src/@types/notify";
import { couponService } from "src/services/coupon";
import { ApplicationState } from "src/store";
import { requestAuthUser } from "src/store/auth/auth.actions";
import NotificationBox from "./NotificationBox";

interface NotificationsProps {
    notifies: Notify[];
}

export default function Notifications(
    props: NotificationsProps
): Readonly<React.ReactElement<NotificationsProps>> {
    const dispatch = useDispatch();

    const authState = useSelector((state: ApplicationState) => state.auth);

    const [coupon] = React.useState<string>(
        localStorage.getItem("coupon") ?? ""
    );
    const [couponValue] = React.useState<string>(
        localStorage.getItem("coupon_value") ?? ""
    );

    const [removeCoupon, setRemoveCoupon] = React.useState<boolean>(false);

    const CouponHandler = async () => {
        if (authState.user?.verifyEmailAddress !== "e2") {
            toast.error("กรุณายืนยันอีเมลเพื่อรับสิทธิ์การใช้งานคูปอง");
            return;
        }

        try {
            await couponService.Redeem(coupon).then((response) => {
                dispatch(requestAuthUser());

                localStorage.removeItem("coupon");
                localStorage.removeItem("coupon_value");

                setRemoveCoupon(true);
                toast.success(response.message);
            });
        } catch ({ message }: any) {
            toast.error(message as string);
        }
    };

    const CouponRemoverHandler = () => {
        localStorage.removeItem("coupon");
        localStorage.removeItem("coupon_value");

        setRemoveCoupon(true);
    };

    return (
        <div className="xver-box">
            <div className="box-content notification-box">
                <h5>การแจ้งเตือน</h5>
                <span style={{ color: "#7c7c7c" }}>
                    ข้อความจากทางผู้ให้บริการหรือประกาศสำคัญ
                </span>
                <div
                    style={{
                        margin: "16px 0",
                    }}
                >
                    {coupon !== "" && !removeCoupon && (
                        <div className="xver-box">
                            <div className="box-content">
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "left",
                                        alignItems: "left",
                                    }}
                                >
                                    <span
                                        style={{
                                            width: "100%",
                                            fontWeight: 500,
                                            color: "#dca105",
                                        }}
                                    >
                                        <i className="fas fa-ticket-alt" />
                                        &nbsp;ค้นพบคูปองที่คุณสามารถใช้งานได้
                                    </span>
                                    <span
                                        style={{
                                            color: "#7c7c7c",
                                        }}
                                    >
                                        คุณสามารถใช้งานคูปองดังกล่าวได้เลย
                                        หากได้ทำการยืนยันอีเมลเพื่อรับสิทธิ์การใช้งานแล้ว{" "}
                                        <b>
                                            ({coupon} มูลค่า {couponValue} บาท)
                                        </b>
                                    </span>
                                    {authState.user?.verifyEmailAddress !==
                                    "e2" ? (
                                        <button
                                            className="xver-btn"
                                            style={{
                                                marginTop: "20px",
                                            }}
                                            onClick={() => void CouponHandler()}
                                            disabled
                                        >
                                            <i className="fad fa-badge-check" />
                                            &nbsp;&nbsp;กรุณายืนยันอีเมลเพื่อใช้งาน
                                        </button>
                                    ) : (
                                        <>
                                            <button
                                                className="xver-btn"
                                                style={{
                                                    marginTop: "20px",
                                                }}
                                                onClick={() =>
                                                    void CouponHandler()
                                                }
                                            >
                                                <i className="fas fa-ticket" />
                                                &nbsp;&nbsp;เปิดใช้งาน
                                            </button>
                                            <button
                                                className="xver-btn-bin"
                                                style={{
                                                    marginTop: "20px",
                                                }}
                                                onClick={() =>
                                                    CouponRemoverHandler()
                                                }
                                            >
                                                <i className="fas fa-trash-alt" />
                                                &nbsp;&nbsp;ลบคูปอง
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {props.notifies.map((notify) => {
                        return (
                            <NotificationBox key={notify.id} notify={notify} />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
