import { FC } from "react";
import { Quota } from "../../@types/quota";
import { User } from "../../@types/user";

interface QuotaSectionProps {
    user: User;
    quotas: Quota[];
}
const QuotaSection: FC<QuotaSectionProps> = ({ user, quotas }) => {

    return (
        <div>
            <div className="xver-box">
                <div className="box-content quota-box">
                    <h5>โควตาทรัพยากรที่ใช้งานได้</h5>
                    {quotas.map((quota) => (
                        <div className="quota-item" key={quota.text}>
                            <div className="quota-top">
                                <h6>{quota.text}</h6>
                                <span>
                                    {quota.current} / {quota.limit}
                                </span>
                            </div>
                            <div className="bar">
                                <div
                                    className="bar-item"
                                    role="progressbar"
                                    style={{
                                        width: `${Math.max(
                                            10,
                                            (quota.current * 100) / quota.limit
                                        )}%`,
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default QuotaSection;
