import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BaremetalInstanceList from "src/components/Instances/BaremetalInstanceList";
import Notifications from "src/components/Notifications/Notifications";
import { notifications } from "src/data/notifications";
import { instanceService } from "src/services/instance";
import { ApplicationState } from "src/store";
import useSWR from "swr";

export default function DedicatedServers(): Readonly<React.ReactElement | null> {
    const authState = useSelector((state: ApplicationState) => state.auth)
    const { data: instances } = useSWR(
        '/v1/baremetals',
        instanceService.getBaremetals,
        {
            refreshInterval: 3000,
        }
    )

    if (!authState.user) {
        return null
    }

    return (
        <div className="block">
            <div className="header-with-element">
                <h2 className="mb-0">
                    <i className="fad fa-server" />
                    &nbsp;เซิร์ฟเวอร์ส่วนตัว
                </h2>
                <Link
                    to="/deploy/dedicated-servers"
                    className="btn btn-primary"
                    style={{ fontSize: '16px' }}
                >
                    <i className="fas fa-plus"></i>
                    &nbsp;&nbsp;สร้างเซิร์ฟเวอร์ส่วนตัว
                </Link>
            </div>
            <div className="xver-content">
                <div className="columns">
                    <div className="column col-7 col-sm-12">
                        {instances ? (
                            <BaremetalInstanceList
                                user={authState.user}
                                instances={instances.sort((a, b) =>
                                    a.id > b.id ? 1 : -1
                                )}
                            />
                        ) : (
                            <div className="loading xver-loading" />
                        )}
                    </div>
                    <div className="column col-5 col-sm-12">
                        <Notifications notifies={notifications} />
                    </div>
                </div>
            </div>
        </div>
    );
}
