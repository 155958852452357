import { FC } from 'react'
import { Instance } from '../../../@types/instance'
import { format } from '../../../utils/number'
import { makePlural } from '../../../utils/string'
import CopyButton from './CopyButton'
import InstanceActionGroups from './InstanceActionGroups'

interface InstanceStatusBadgeProps {
	status: string
}
const InstanceStatusBadge: FC<InstanceStatusBadgeProps> = ({ status }) => {
	if (status === 'running') {
		return <label className="label label-success">Running</label>
	}
	if (status === 'stopped') {
		return <label className="label label-error">Stopped</label>
	}
	return <label className="label label-error">{status}</label>
}

interface OverviewTabProps {
	data: Instance
}
const OverviewTab: FC<OverviewTabProps> = ({ data }) => (
	<div>
		<div className="columns">
			<div className="column col-4 col-sm-6">
				<div className="xver-instance-meter">
					<h5>CPU Usage</h5>
					<h6>
						{format(data.state.cpu * 100)} <span>%</span>
					</h6>
				</div>
			</div>
			<div className="column col-4 col-sm-6">
				<div className="xver-instance-meter">
					<h5>Memory Usage</h5>
					<h6>
						{format(data.state.memory)} <span>%</span>
					</h6>
				</div>
			</div>
			<div className="column col-4 col-sm-12">
				<div className="xver-instance-meter">
					<h5>Current Charges</h5>
					<h6>
						{format(data.charges.current, 2)} <span>฿</span>
					</h6>
				</div>
			</div>

			{data.state.power === 'stopped' && (
				<div className="column col-12 instance-stopped-warning">
					<div className="toast toast-default">
						<i className="fad fa-exclamation-triangle" /> เซิร์ฟเวอร์ของคุณจะถูกคิดเงินตามปกติ จนกว่าเซิร์ฟเวอร์จะถูกลบ
					</div>
				</div>
			)}

			<div className="column col-4 col-sm-12">
				<div className="xver-instance-info-row">
					<div className="info-title">Status</div>
					<div className="info-desc">
						<InstanceStatusBadge status={data.state.power} />
					</div>
				</div>
				<div className="xver-instance-info-row">
					<div className="info-title">Hostname</div>
					<div>
						<div className="info-desc">
                            <CopyButton
                                textToCopy={data.hostname}

                                label={data.hostname}
                            />
                        </div>
					</div>
				</div>
				<div className="xver-instance-info-row">
					<div className="info-title">IP Address</div>
					<div>
						<div className="info-desc">
                            <CopyButton
                                textToCopy={data.network.ipAddress}

                                label={data.network.ipAddress}
                            />
                        </div>
					</div>
				</div>
				{data.username && (
					<div className="xver-instance-info-row">
						<div className="info-title">Username</div>
						<div>
							<div className="info-desc">
                                <CopyButton
                                    textToCopy={data.username}

                                    label={data.username}
                                />
                            </div>
						</div>
					</div>
				)}
				{data.password && (
					<div className="xver-instance-info-row">
						<div className="info-title">Password</div>
						<div>
							<div className="info-desc" style={{ fontFamily: "monospace" }}>
                                <CopyButton
                                    textToCopy={data.password}

                                    label={data.password}
                                />
                            </div>
						</div>
					</div>
				)}
			</div>
			<div className="column col-4 col-sm-12">
				<div className="xver-instance-info-row">
					<div className="info-title">CPU</div>
					<div className="info-desc">
						<b>{data.spec.cpu}</b> {makePlural('vCPU', data.spec.cpu)}
					</div>
				</div>
				<div className="xver-instance-info-row">
					<div className="info-title">Memory</div>
					<div className="info-desc">
						<b>{data.spec.memory / 1024}</b> GB
					</div>
				</div>
				<div className="xver-instance-info-row">
					<div className="info-title">Storage</div>
					<div className="info-desc">
						<b>{data.spec.disk}</b> GB SSD
					</div>
				</div>
				<div className="xver-instance-info-bandwidth">
					<div className="info-title">Bandwidth</div>
					<progress className="progress" value={(data.bandwidth.usage * 100) / data.bandwidth.limit} max={100} />
					<div className="info-desc">
						<b>{format(data.bandwidth.usage, 0)}</b> GB used of <b>{format(data.bandwidth.limit, 0)}</b> GB
					</div>
					<div className="info-desc">
						* คิดค่าบริการเพิ่มเติม <b>0.3</b> บาท / GB เมื่อใช้งานเกินกำหนด
					</div>
					{data.bandwidth.usage > data.bandwidth.limit && (
						<div className="info-desc">
							ค่าบริการ <b>{format(data.bandwidth.charges, 0)}</b> บาท สำหรับส่วนเกิน&nbsp;
							<b>{format(data.bandwidth.usage - data.bandwidth.limit)}</b> GB
						</div>
					)}
				</div>
			</div>
			<div className="column col-4 col-sm-12">
				<InstanceActionGroups data={data} />
			</div>
		</div>
	</div>
)

export default OverviewTab
