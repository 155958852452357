import { CommonResponse } from '../@types/api'
import { BaremetalInstance, Instance, InstanceConsoleResponse } from '../@types/instance'
import { BaremetalLanuchOptions, LaunchBaremetalInstanceData, LaunchInstanceData, LaunchOptions } from '../@types/launch'
import api from '../utils/api'

export const instanceService = {
	getLaunchOptions(): Promise<LaunchOptions> {
		return api.get('/v1/launch')
	},
    getBaremetalLanuchOptions(): Promise<BaremetalLanuchOptions> {
        return api.get('/v1/launch/baremetal');
    },
    lanuchBaremetal(data: LaunchBaremetalInstanceData): Promise<CommonResponse> {
        return api.post('/v1/launch/baremetal', data)
    },
	launchInstance(data: LaunchInstanceData): Promise<CommonResponse> {
		return api.post('/v1/launch', data)
	},
    getBaremetals(): Promise<BaremetalInstance[]> {
        return api.get('/v1/baremetals')
    },
    getBaremetal(id: any): Promise<BaremetalInstance> {
        return api.get(`/v1/baremetals/${id}`)
    },
	getInstances(): Promise<Instance[]> {
        return api.get('/v1/instances');
	},
	getInstance(id: any): Promise<Instance> {
		return api.get(`/v1/instances/${id}`)
	},

	invokeAction(id: any, action: string): Promise<CommonResponse> {
		return api.post(`/v1/instances/${id}/${action}`)
	},
	destroy(id: any): Promise<CommonResponse> {
		return api.delete(`/v1/instances/${id}`)
	},
    destroyBaremetal(id: any): Promise<CommonResponse> {
        return api.delete(`/v1/baremetals/${id}`)
    },
	openConsole(id: any): Promise<InstanceConsoleResponse> {
		return api.post(`/v1/instances/${id}/console`)
	},
}
