import { User } from '../@types/user'

interface ChatwootSettings {
    showPopoutButton: boolean
    position: string
    type: string
    launcherTitle: string
    locale: string
    useBrowserLanguage: boolean
    darkMode: string
    baseDomain: string
}

interface ChatwootSDK {
    run: (config: { websiteToken: string; baseUrl: string }) => void
}

interface Chatwoot {
    setUser: (uid: any, obj: any) => void
    setCustomAttributes: (obj: any) => void
    reset: () => void
}

declare global {
    interface Window {
        chatwootSettings: ChatwootSettings
        chatwootSDK: ChatwootSDK
        $chatwoot: Chatwoot
        $chatwoot_user: User
    }
}

export const setUser = (user: User) => {
    window.$chatwoot_user = user
    initUser()
}
export const reset = () => {
    // deleteCookiesStartingWith('cw_')
    window.$chatwoot.reset()
}
export const initUser = () => {
    console.log('initUser start')
    // if (!window.$chatwoot_user) {
    //     console.debug('chatwoot_user is not defined')
    //     return
    // }
    // console.log('initUser pass')
    const user: User = window.$chatwoot_user
    window.$chatwoot.setUser(user.id, {
        name: `${user.information.firstName} ${user.information.lastName}`,
        // email: user.email,
        // identifier_hash: user.identifier,
    })

    window.$chatwoot.setCustomAttributes({
        userid: user.id,
        status: user.status,
        email: user.email,
        kyc_level: user.kyc.level,
        kyc_status: user.kyc.status,
    })
}
