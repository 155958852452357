import { useFormik } from 'formik'
import qs from 'querystring'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import BaseButton from '../components/Base/BaseButton'
import { authService } from '../services/auth'

const VerifyPhone = () => {
	const q = qs.parse(window.location.search.replace('?', ''))
	const token = q.t as string

	const history = useHistory()
	const { values, isSubmitting, setFieldValue, handleChange, handleSubmit } = useFormik({
		initialValues: {
			phoneNumber: q.phone,
			otpRef: '',
			otpCode: '',
		},
		onSubmit: async (values) => {
			try {
				const res = await authService.verifyPhone(token, values)

				localStorage.setItem('x-auth', res.token)
				history.push('/cloud-servers')
			} catch ({ message }) {
				toast.error(message as string)
			}
		},
	})

	const [sending, setSending] = useState(false)
	const sendVerificationCode = async () => {
		setSending(true)

		try {
			const res = await authService.sendVerificationCode(token)

			setFieldValue('otpRef', res.data.otpRef)
			toast.success(res.message)
		} catch ({ message }) {
			toast.error(message as string)
		} finally {
			setSending(false)
		}
	}

	return (
		<form autoComplete="off" onSubmit={handleSubmit}>
			<div className="form-group">
				<label className="form-label">เบอร์โทรศัพท์</label>
				<div className="input-group">
					<input type="text" className="form-input " defaultValue={values.phoneNumber} readOnly={true} />
					<BaseButton
						type="button"
						color="primary"
						classes="input-group-btn"
						onClick={sendVerificationCode}
						loading={sending}
					>
						ส่งรหัสยืนยัน
					</BaseButton>
				</div>
			</div>
			{values.otpRef && (
				<div className="form-group">
					<label className="form-label">รหัสยืนยัน (Ref. {values.otpRef})</label>
					<input
						type="text"
						name="otpCode"
						className="form-input"
						placeholder="รหัสยืนยัน"
						required
						onChange={handleChange}
					/>
				</div>
			)}
			<div className="form-group mt-1">
				{values.otpRef && (
					<BaseButton type="submit" color="primary" classes="mr-05" loading={isSubmitting}>
						ยืนยัน
					</BaseButton>
				)}
				<Link to="/login" className="btn btn-default">
					ยกเลิก
				</Link>
			</div>
		</form>
	)
}

export default VerifyPhone
