import React, { ChangeEvent, FC, FormEvent } from 'react'
import BaseButton from '../Base/BaseButton'
import BaseInput from '../Base/BaseInput'
import BaseTermOfService from '../Base/BaseTermOfService'

interface SettingProps {
    loading: boolean
    hostname: string
    enableStartupScript: boolean
    startupScriptChecked: boolean

    setFieldValue: (field: string, value: any) => void
    handleChange: (e: ChangeEvent<any>) => void
    handleSubmit: (e: FormEvent<any>) => void
}

const ServerSetting: FC<SettingProps> = ({
    loading,
    hostname,
    enableStartupScript,
    startupScriptChecked,

    setFieldValue,
    handleChange,
    handleSubmit,
}) => {
    const [agree, setAgree] = React.useState<boolean>(false)

    return (
        <form autoComplete="off" onSubmit={handleSubmit}>
            {enableStartupScript && (
                <div className="startup-script-section">
                    <label className="form-checkbox">
                        <input
                            type="checkbox"
                            defaultChecked={startupScriptChecked}
                            onChange={() =>
                                setFieldValue(
                                    'enableStartupScript',
                                    !startupScriptChecked
                                )
                            }
                        />
                        <i className="form-icon" />
                        Startup Script
                    </label>

                    {startupScriptChecked && (
                        <textarea
                            name="startupScript"
                            className="form-input"
                            placeholder="apt update && apt upgrade -y ..."
                            rows={3}
                            onChange={handleChange}
                        />
                    )}
                </div>
            )}

            <BaseInput
                type="text"
                name="hostname"
                label="Hostname"
                required
                defaultValue={hostname}
                onChange={handleChange}
            />

            <div className="form-group">
                <BaseButton
                    type="submit"
                    color="primary"
                    classes="btn-lg btn-block"
                    loading={loading}
                    disabled={!agree}
                >
                    เริ่มต้นการสร้างเซิร์ฟเวอร์
                </BaseButton>
            </div>

            <div className="form-group">
                <BaseTermOfService
                    to="/term-of-service"
                    custom_text1="เมื่อสร้างเครื่องแล้ว "
                    custom_text2="หากทำการปิดเครื่องจะมีการคิดเงินต่อจนกว่าจะดำเนินการลบเซิร์ฟเวอร์"
                    state={[agree, setAgree]}
                />
            </div>
        </form>
    )
}

export default ServerSetting
