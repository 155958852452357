import React from "react";

import qs from "querystring";

import { useHistory } from "react-router";

import { useFormik } from "formik";

import { authService } from "../services/auth";
import { toast } from "react-toastify";

import BaseButton from "../components/Base/BaseButton";
import { Text } from "@geist-ui/core";
import BaseInput from "src/components/Base/BaseInput";

const ResetEmailAddress: React.FC = () => {
    const q = qs.parse(window.location.search.replace("?", ""));

    const token = q.token as string;

    const history = useHistory();

    const { isSubmitting, handleChange, handleSubmit } = useFormik({
        initialValues: {
            otpCode: "",
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await authService.handleResetEmailAddress(
                    values.otpCode,
                    token
                );

                toast.success(response.message);
                history.push("/sign-in");
            } catch ({ message }: any) {
                setSubmitting(false);
                toast.error(message as string);
            }
        },
    });

    return (
        <div>
            <form autoComplete="off" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="form-label">
                        กรุณาใส่ข้อมูลให้ครบถ้วนเพื่อดำเนินการ
                    </label>
                    <BaseInput
                        type="text"
                        name="otpCode"
                        label="รหัสยืนยนตัวตน"
                        required
                        onChange={handleChange}
                    />
                    <div
                        style={{
                            marginTop: "0.7rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                        }}
                    >
                        <Text type="secondary">
                            <Text type="error">
                                อีเมลล์เดิมของคุณจะใช้ไม่ได้และคุณต้องล็อกอิน
                                <br />
                                เข้าอีเมลล์ใหม่ที่คุณพึ่งเปลี่ยนไปเพื่อยืนยันตัวตน
                            </Text>
                            <Text type="warning">
                                *หากพบปัญหาใด สามารถติดต่อสอบถามแฟนเพจได้*
                            </Text>
                        </Text>
                    </div>
                </div>
                <div className="form-group">
                    <BaseButton
                        type="submit"
                        color="primary"
                        classes="mr-2"
                        loading={isSubmitting}
                    >
                        ยืนยันด้วยรหัสดังกล่าว
                    </BaseButton>
                </div>
            </form>
        </div>
    );
};

export default ResetEmailAddress;
