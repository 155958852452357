import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { User } from '../../@types/user'
import { setAuthUser } from './auth.actions'

export interface AuthState {
	user: User | undefined
}

export const defaultState: AuthState = {
	user: undefined,
}

export default reducerWithInitialState(defaultState).case(setAuthUser, (state, payload) => ({
	...state,
	user: payload,
}))
