import { useFormik } from 'formik'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import BaseAlert from '../components/Base/BaseAlert'
import BaseButton from '../components/Base/BaseButton'
import BaseInput from '../components/Base/BaseInput'
import { authService } from '../services/auth'

const ResetPassword = () => {
	const history = useHistory()
	const { isSubmitting, handleChange, handleSubmit } = useFormik({
		initialValues: {
			email: '',
			phoneNumber: '',
		},
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const res = await authService.requestResetPassword(values)
				if (res.data) {
					toast.info(res.message)
					history.push(`/reset-password/${res.data.userID}`)
				}
			} catch ({ message }) {
				setSubmitting(false)
				toast.error(message as string)
			}
		},
	})

	return (
		<form autoComplete="off" onSubmit={handleSubmit}>
			<BaseAlert info="กรุณากรอกข้อมูลให้ถูกต้องเพื่อรีเซ็ตรหัสผ่าน" />
			<BaseInput type="email" name="email" label="อีเมล" required onChange={handleChange} />
			<BaseInput type="text" name="phoneNumber" label="เบอร์โทรศัพท์" required onChange={handleChange} />

			<div className="form-group">
				<BaseButton type="submit" color="primary" classes="mr-2" loading={isSubmitting}>
					ยืนยัน
				</BaseButton>
				<Link to="/sign-in" className="btn btn-default">
					ย้อนกลับ
				</Link>
			</div>
		</form>
	)
}

export default ResetPassword
