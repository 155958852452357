import React from 'react'

const TermOfService = () => (
    <div className="auth-body">
        <div className="container">
            <div className="columns">
                <div className="column col-5 col-md-12 col-mx-auto mt-2">
                    <div className="card">
                        <div className="card-body">
                            <h3 className="text-center">ข้อตกลงการใช้บริการ</h3>
                            <ol>
                                <li>
                                    ผู้ใช้บริการต้องระบุข้อมูลที่ใช้ลงทะเบียนตามความจริงทั้งหมด
                                    หากพบว่ามีข้อมูลใดๆ ที่ไม่เป็นจริง
                                    ทางผู้ให้บริการของสงวนสิทธิ์ในการยกเลิกและระงับบริการโดยไม่แจ้งให้ทราบล่วงหน้า
                                </li>
                                <li>
                                    ห้ามผู้ใช้บริการ ใช้งานไปในทางที่ผิดกฏหมาย
                                    หรือ ทำให้เกิดความเสียหายแก่บุคคลอื่น
                                </li>
                                <li>
                                    ห้ามใช้บริการเพื่อโจมตีระบบเซิร์ฟเวอร์
                                    หรือวิธีอื่นๆ ที่มีจุดประสงค์คล้ายกัน
                                </li>
                                <li>
                                    ห้ามผู้ใช้บริการ
                                    ใช้ในการเก็บหรือเผยแพร่ข้อมูล ภาพ เสียง
                                    หรือข้อความที่ผิดกฎหมาย รวมไปถึงบริการ
                                    bittorrent
                                    ที่จัดทำขึ้นโดยไม่ได้รับอณุญาตจากเจ้าของลิขสิทธิ์อย่างเป็นทางการ
                                </li>
                                <li>
                                    ห้ามผู้ใช้บริการ กระทำการใดๆ
                                    โดยที่มีการใช้งาน CPU / Network / Memory
                                    สูงจนทำให้ระบบมีปัญหาหรือกระทบต่อผู้ใช้บริการรายอื่น
                                </li>
                                <li>
                                    การกระทำใดๆ
                                    ถือว่าเป็นการกระทำที่อยู่ภายใต้ความรับผิดชอบของผู้ใช้บริการแต่เพียงผู้เดียว
                                    ผู้ให้บริการไม่มีส่วนเกี่ยวข้องใดๆ ทั้งสิ้น
                                </li>
                                <li>
                                    ผู้ให้บริการไม่รับผิดชอบเกี่ยวกับประสิทธิภาพ
                                    ความล่าช้า
                                    รวมถึงการสูญเสียข้อมูลที่อยู่นอกเหนือการความคุมของผู้ให้บริการ
                                </li>
                                <li>
                                    ผู้ให้บริการไม่ได้เป็นผู้บริการด้าน Software
                                    ดังนั้นปัญหาในการใช้งานด้านต่างๆ
                                    ผู้บริการขอสงวนสิทธิ์ในการช่วยเหลือเฉพาะปัญหาจากเซิร์ฟเวอร์เท่านั้น
                                </li>
                                <li>
                                    ผู้ให้บริการมีสิทธิ์ที่จะเข้าตรวจสอบข้อมูลหรือไฟล์ต่างๆ
                                    ในเซิร์ฟเวอร์ของผู้ใช้บริการหากเกิดปัญหา
                                    โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                </li>
                                <li>
                                    ในกรณีที่ผู้ใช้บริการไม่ชำระค่าบริการตามกำหนด
                                    ผู้ให้บริการขอ
                                    <span className="text-error">
                                        ระงับการให้บริการและจะดำเนินการลบข้อมูลทั้งหมดออกจากระบบในทันที
                                    </span>
                                </li>
                                <li>
                                    ผู้ใหบริการขอสงวนสิทธิ์ในการยกเลิกบริการทันที
                                    หากมีการละเมิดข้อตกลงการใช้บริการนี้
                                </li>
                                <li>
                                    ผู้ให้บริการขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขในการให้บริการโดยไม่จำเป็นแจ้งให้ทราบล่วงหน้า
                                </li>
                                <li>
                                    หากผู้ใช้บริการมีการสร้างเครื่องและทำการปิดเครื่องทางผู้ให้บริการยังคงคิดเงินต่อจนกว่าจะลบเครื่องหรือดำเนินการลบเซิร์ฟเวอร์
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default TermOfService
