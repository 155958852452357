import React from "react";
import { BaremetalInstance } from "src/@types/instance";
import CopyButton from "./CopyButton";

interface BaremetalOverviewTabProps {
    data: BaremetalInstance;
}

export default function BaremetalOverviewTab({ data }: BaremetalOverviewTabProps): Readonly<React.ReactElement<BaremetalOverviewTabProps> | null> {
    const InstanceStatusBadge = ({ status }: { status: string }) => {
        if (status === 'running') {
            return <label className="label label-success">Running</label>
        }

        return <label className="label label-error">{status}</label>
    }

    return (
        <div>
		<div className="columns">
			{data.power === 'stopped' && (
				<div className="column col-12 instance-stopped-warning">
					<div className="toast toast-default">
						<i className="fad fa-exclamation-triangle" /> เซิร์ฟเวอร์ของคุณจะถูกคิดเงินตามปกติ จนกว่าเซิร์ฟเวอร์จะถูกลบ
					</div>
				</div>
			)}

			<div className="column col-4 col-sm-12">
				<div className="xver-instance-info-row">
					<div className="info-title">Status</div>
					<div className="info-desc">
						<InstanceStatusBadge status={data.power} />
					</div>
				</div>
				<div className="xver-instance-info-row">
					<div className="info-title">Hostname</div>
					<div>
						<CopyButton
                            textToCopy={data.hostname}

                            label={data.hostname}
                        />
					</div>
				</div>
				<div className="xver-instance-info-row">
					<div className="info-title">IP Address</div>
					<div>
						{data.network.ip_addresses.length > 0 && (
                            <>
                                {data.network.ip_addresses.map((ip) => {
                                    return (
                                        <div key={ip}>
                                            <div className="info-desc">{ip}</div>
                                            <CopyButton textToCopy={ip} />
                                        </div>
                                    )
                                })}
                            </>
                        )}
					</div>
				</div>
				{data.username && (
					<div className="xver-instance-info-row">
						<div className="info-title">Username</div>
						<div>
							<div className="info-desc">{data.username}</div>
							<CopyButton
                                textToCopy={data.username}

                                label={data.username}
                            />
						</div>
					</div>
				)}
				{data.password && (
					<div className="xver-instance-info-row">
						<div className="info-title">Password</div>
						<div>
							<div className="info-desc" style={{ fontFamily: "monospace" }}>
                                <CopyButton
                                    textToCopy={data.password}

                                    label={data.password}
                                />
                            </div>
						</div>
					</div>
				)}
			</div>
			<div className="column col-4 col-sm-12">
				<div className="xver-instance-info-row">
					<div className="info-title">CPU</div>
					<div className="info-desc">
						<b>{data.specs.cpu}</b>
					</div>
				</div>
				<div className="xver-instance-info-row">
					<div className="info-title">Memory</div>
					<div className="info-desc">
						<b>{data.specs.ram}</b>
					</div>
				</div>
				<div className="xver-instance-info-row">
					<div className="info-title">Storage</div>
					<div className="info-desc">
						<b>{data.specs.disk}</b> GB SSD
					</div>
				</div>
			</div>
		</div>
	</div>
    );
}
