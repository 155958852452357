import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { BaremetalInstance } from 'src/@types/instance'
import { User } from 'src/@types/user'
import OSIcon from './OSIcon'
import { getOSFullName } from 'src/utils/os'

interface BaremetalInstanceListProps {
    user: User
    instances: BaremetalInstance[]
}

export default function BaremetalInstanceList(
    props: BaremetalInstanceListProps
): Readonly<React.ReactElement<BaremetalInstanceListProps> | null> {
    const renderInstanceListAlert = (
        user: User,
        instances: BaremetalInstance[]
    ) => {
        if (user.status === 'insufficient_funds') {
            return (
                <div className="toast toast-error">
                    <i className="fad fa-exclamation-triangle" />
                    <span>
                        บัญชีของคุณถูกระงับการใช้งานเนื่องจากยอดคงเหลือไม่เพียงพอ
                        กรุณาเติมเงินให้เพียงพอต่อการใช้งานเพื่อปลดล็อคบัญชีของคุณ
                        โดยเซิร์ฟเวอร์จะถูกคิดเงินตามปกติในขณะที่ปิดอยู่เพื่อรักษาข้อมูล&nbsp;
                        <b>
                            หากคุณไม่ดำเนินการชำระยอดคงค้างภายใน 3 วัน{' '}
                            <small>(นับจากวันที่ถูกระงับ)</small>&nbsp;
                            เซิร์ฟเวอร์ทั้งหมดจะถูกลบและไม่สามารถกู้คืนได้
                        </b>
                    </span>
                </div>
            )
        }
        if (instances.filter((ins) => ins.power === 'install').length > 0) {
            return (
                <div className="toast toast-default">
                    <i className="fad fa-info-circle" />{' '}
                    การสร้างเซิร์ฟเวอร์ใช้เวลา 1 ถึง 23 ชั่วโมงโดยประมาณ
                </div>
            )
        }

        return null
    }

    return (
        <div className="xver-instance-list-wrapper">
            {renderInstanceListAlert(props.user, props.instances)}

            {props.instances.length <= 0 ? (
                <div className="xver-box no-instance">
                    <div className="box-content text-center">
                        <span className="fa-stack fa-2x">
                            <i className="fas fa-server fa-stack-1x" />
                            <i
                                className="fas fa-ban fa-stack-2x"
                                style={{ color: 'Tomato' }}
                            />
                        </span>
                        <h2>ไม่มีเซิร์ฟเวอร์</h2>
                        <h6>ดูเหมือนว่าคุณยังไม่ได้สร้างเซิร์ฟเวอร์เลย</h6>
                        <Link
                            to="/deploy/dedicated-servers"
                            className="btn btn-primary"
                        >
                            สร้างเซิร์ฟเวอร์
                        </Link>
                    </div>
                </div>
            ) : (
                props.instances.map((instance) => {
                    return (
                        <div
                            className="xver-instance-list-item"
                            key={instance.id}
                        >
                            <div className="xver-instance-info">
                                <div className="instance-icon">
                                    <div
                                        className={clsx(
                                            'instance-icon-spinner',
                                            instance.power
                                        )}
                                    >
                                        <OSIcon slug={instance.image.os} />
                                    </div>
                                </div>
                                <span>
                                    <h3>
                                        <b>{instance.hostname}</b> -{' '}
                                        {instance.network.ip_addresses[0]}
                                    </h3>
                                    <h6>
                                        {getOSFullName(instance.image.slug)}
                                    </h6>
                                    <h6>
                                        <b>{instance.specs.cpu}</b>
                                        {' / '}
                                        <b>{instance.specs.ram}</b>
                                    </h6>
                                </span>
                            </div>
                            <div className="xver-instance-action">
                                {!['install'].includes(instance.power) && (
                                    <Link
                                        to={`/dedicated-servers/${instance.id}`}
                                        className="btn btn-primary btn-action"
                                    >
                                        <i className="fa fa-server" />
                                    </Link>
                                )}
                            </div>
                        </div>
                    )
                })
            )}
        </div>
    )
}
