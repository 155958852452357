export const getOSIcon = (os: string) => {
	switch (os) {
		case 'windows-server': {
			return 'fa-windows'
		}
		case 'ubuntu': {
			return 'fa-ubuntu'
		}
		case 'centos': {
			return 'fa-centos'
		}
		case 'debian': {
			return 'fa-debian'
		}
		default: {
			return 'fa-linux'
		}
	}
}

export const getOSFullName = (slug: string) => {
	switch (slug) {
		case 'win-2012-r2': {
			return 'Windows Server 2012 R2'
		}
		case 'win-2016': {
			return 'Windows Server 2016'
		}
		case 'ubuntu-20-04-lts': {
			return 'Ubuntu 20.04 LTS'
		}
		case 'ubuntu-18-04-lts': {
			return 'Ubuntu 18.04 LTS'
		}
		case 'ubuntu-22-04': {
			return 'Ubuntu 22.04'
		}
		case 'centos-8-stream': {
			return 'CentOS 8 Stream'
		}
		case 'centos-8-3': {
			return 'CentOS 8.3'
		}
		case 'centos-8-4': {
			return 'CentOS 8.4'
		}
		case 'centos-7': {
			return 'CentOS 7'
		}
		case 'debian-11': {
			return 'Debian 11'
		}
		default: {
			return slug
		}
	}
}
