import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Instance } from '../../../@types/instance'
import { instanceService } from '../../../services/instance'
import BaseButton from '../../Base/BaseButton'

interface InstanceActionGroupsProps {
    data: Instance
}
const InstanceActionGroups: FC<InstanceActionGroupsProps> = ({ data }) => {
    const [loading, setLoading] = useState('')
    const syncInvokeAction = async (action: string) => {
        if (loading !== '' || data.hasPendingTasks) {
            return
        }

        setLoading(action)
        try {
            await instanceService.invokeAction(data.id, action)
        } catch (e) {
            toast.error((e as Error).message)
        }
    }
    useEffect(() => {
        if (!data.hasPendingTasks) {
            setLoading('')
        }
    }, [data])

    const openConsole = async () => {
        if (loading !== '') {
            return
        }

        setLoading('console')
        try {
            const res = await instanceService.openConsole(data.id)

            setTimeout(() => {
                window.open(res.url, '_blank')
            })
        } catch (e) {
            toast.error((e as Error).message)
        } finally {
            setLoading('')
        }
    }

    if (loading || data.hasPendingTasks) {
        return <div className="loading xver-loading" />
    }
    if (data.state.power === 'running') {
        return (
            <div className="instance-action-groups">
                <BaseButton
                    type="button"
                    color="warning"
                    classes="btn-lg btn-instance-action"
                    onClick={() => syncInvokeAction('reboot')}
                >
                    <i className="fad fa-sync-alt" /> &nbsp; รีบูต
                </BaseButton>
                <BaseButton
                    type="button"
                    color="error"
                    classes="btn-lg btn-instance-action"
                    onClick={() => syncInvokeAction('shutdown')}
                >
                    <i className="fad fa-stop-circle" /> &nbsp; ปิดเครื่อง
                </BaseButton>
                <BaseButton
                    type="button"
                    color="error"
                    classes="btn-lg btn-instance-action"
                    onClick={() => syncInvokeAction('reset')}
                >
                    <i className="fad fa-bolt" /> &nbsp; บังคับ รีบูต
                </BaseButton>
                <BaseButton
                    type="button"
                    color="primary"
                    classes="btn-lg btn-instance-action"
                    onClick={openConsole}
                >
                    <i className="fad fa-desktop" /> &nbsp; หน้าจอควบคุม
                </BaseButton>
            </div>
        )
    }
    return (
        <div className="instance-action-groups">
            <BaseButton
                type="button"
                color="success"
                classes="btn-lg btn-instance-action"
                onClick={() => syncInvokeAction('start')}
            >
                <i className="fad fa-play-circle" /> &nbsp; เปิดเครื่อง
            </BaseButton>
        </div>
    )
}

export default InstanceActionGroups
