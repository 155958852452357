import React from "react";
import { useHistory } from "react-router-dom";

import qs from "querystring";

import { authService } from "../services/auth";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";

import BaseButton from "../components/Base/BaseButton";
import { Text } from "@geist-ui/core";
import { useFormik } from "formik";
import BaseInput from "src/components/Base/BaseInput";

const VerifyEmailAddress: React.FC = () => {
    const q = qs.parse(window.location.search.replace("?", ""));
    const email = q.email as string;
    const level = q.level as string;
    const token = q.token as string;

    const history = useHistory();

    if (!email || !level || !token) {
        history.push("/sign-in");
    }

    const [receivedState, setReceivedState] = React.useState<Boolean>(false);
    const [messageReceived, setMessageReceieved] = React.useState<String>("");

    const [sending, setSending] = React.useState(false);

    const [changeState, setChangeState] = React.useState<Boolean>(false);

    const { isSubmitting, handleChange, handleSubmit } = useFormik({
        initialValues: {
            newEmailAddress: '',
            currentlyPassword: ''
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await authService.requestResetEmailAddress(token, values);

                toast.success(response.message);
                history.push(`/reset-email-address?token=${token}`);

            } catch ({ message }: any) {
                setSubmitting(false);
                toast.error(message as string);
            }
        },
    });

    const sendVerificationCode = async () => {
        setSending(true);

        try {
            const res = await authService.sendEmailAddressVerificationCode(
                token
            );

            setReceivedState(true);
            setMessageReceieved(res.message);
        } catch ({ message }: any) {
            setReceivedState(true);
            setMessageReceieved(message as string);

            toast.error(message as string);
        } finally {
            setSending(false);
        }
    };

    return (
        <div>
            {receivedState ? (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            marginBottom: "0.5rem",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Text type="success" h5 style={{ textAlign: "center" }}>
                            {messageReceived}
                        </Text>
                        <Text type="secondary">
                            (กรุณาเช็คที่กล่องข้อความอีเมลล์ของท่าน)
                        </Text>
                    </div>
                    <Link to={"/sign-in"}>
                        <BaseButton style={{ width: "100%" }} color="primary">
                            กลับสู่หน้าหลัก
                        </BaseButton>
                    </Link>
                </div>
            ) : (
                <div>
                    {changeState ? (
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label className="form-label">
                                    กรุณาใส่ข้อมูลให้ครบถ้วนเพื่อดำเนินการ
                                </label>
                                <BaseInput
                                    type="email"
                                    name="newEmailAddress"
                                    label="อีเมลล์ที่ต้องการตั้งใหม่"
                                    required
                                    onChange={handleChange}
                                />
                                <BaseInput
                                    type="password"
                                    name="currentlyPassword"
                                    label="พาสเวิร์ดของคุณที่ใช้อยู่"
                                    required
                                    onChange={handleChange}
                                />
                                <div
                                    style={{
                                        marginTop: "0.7rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "start",
                                        alignItems: "start",
                                    }}
                                >
                                    <Text type="secondary">
                                        หากเปลี่ยนอีเมลล์แล้วอีเมลล์ดังกล่าวต้องเป็นอีเมลล์ที่{" "}
                                        <br />
                                        ใช้งานได้จริงและสามารถยืนยันได้
                                        หลังจากกดเปลี่ยน
                                        <br />
                                        <br />
                                        <Text type="error">
                                            อีเมลล์เดิมของคุณจะใช้ไม่ได้และคุณต้องล็อกอิน
                                            <br />
                                            เข้าอีเมลล์ใหม่ที่คุณพึ่งเปลี่ยนไปเพื่อยืนยันตัวตน
                                        </Text>
                                        <Text type="warning">
                                            *หากพบปัญหาใด
                                            สามารถติดต่อสอบถามแฟนเพจได้*
                                        </Text>
                                    </Text>
                                </div>
                            </div>
                            <div className="form-group">
                                <BaseButton
                                    type="submit"
                                    color="primary"
                                    classes="mr-2"
                                    loading={isSubmitting}
                                >
                                    เปลี่ยนอีเมลล์
                                </BaseButton>
                                <BaseButton
                                    type="button"
                                    color="secondary"
                                    onClick={() => {
                                        setChangeState(false);
                                    }}
                                >
                                    ย้อนกลับ
                                </BaseButton>
                            </div>
                        </form>
                    ) : (
                        <form autoComplete="off">
                            <div className="form-group">
                                <label className="form-label">
                                    อีเมลล์ดังกล่าวเป็นอีเมลล์ที่คุณใช้บน{" "}
                                    <b>Xver</b> หรือไม่?
                                </label>
                                <input
                                    type="text"
                                    className="form-input "
                                    defaultValue={email}
                                    readOnly={true}
                                />
                                <div
                                    style={{
                                        marginTop: "0.7rem",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "start",
                                        alignItems: "start",
                                    }}
                                >
                                    <Text type="secondary">
                                        เนื่องจากเราเริ่มมีนโยบายการยืนยันตัวตนผ่านอีเมลล์{" "}
                                        <br />
                                        ผู้ใช้งานทุกคนจึงต้องยืนยันอีเมลล์เพื่อเข้าใช้งาน{" "}
                                        <br />
                                        <br />
                                        <Text type="warning">
                                            *หากพบปัญหาใด
                                            สามารถติดต่อสอบถามแฟนเพจได้*
                                        </Text>
                                    </Text>
                                </div>
                            </div>
                            <div className="form-group">
                                <BaseButton
                                    type="button"
                                    color="primary"
                                    classes="mr-05"
                                    onClick={sendVerificationCode}
                                    loading={sending}
                                >
                                    ส่งลิงก์ยืนยัน
                                </BaseButton>
                                {level === "e0" && (
                                    <BaseButton
                                        type="button"
                                        color="warning"
                                        classes="mr-05"
                                        onClick={() => {
                                            setChangeState(true);
                                        }}
                                    >
                                        เปลี่ยนอีเมลล์
                                    </BaseButton>
                                )}
                                <Link to="/login" className="btn btn-default">
                                    ยกเลิก
                                </Link>
                            </div>
                        </form>
                    )}
                </div>
            )}
        </div>
    );
};

export default VerifyEmailAddress;
