import classNames from 'classnames'
import React, { FC } from 'react'
import { XVerPackage } from '../../@types/launch'
import { format } from '../../utils/number'
import { makePlural } from '../../utils/string'

const filterPackage = (
    data: XVerPackage[],
    isWindowsServer: boolean
): XVerPackage[] =>
    isWindowsServer
        ? data.filter((pkg) => pkg.cpu >= 1 && pkg.memory >= 2048)
        : data

interface SelectSizeProps {
    data: XVerPackage[]
    isWindowsServer: boolean

    selected: number
    select: (v: number) => void
}
const SelectPackage: FC<SelectSizeProps> = ({
    data,
    isWindowsServer,
    selected,
    select,
}) => {
    return (
        <section style={{ position: 'relative' }}>
            <div className='xver-package-wrapper'>
                {filterPackage(data, isWindowsServer).map((pkg) => (
                    <div
                        key={pkg.id}
                        className={classNames('xver-package', {
                            active: pkg.id === selected,
                            recommended: pkg.recommend && pkg.id !== selected,
                        })}
                        onClick={() => select(pkg.id)}
                    >
                        {pkg.recommend && (
                            <div className='xver-recommended'>
                                <i className='fad fa-gift' />
                                &nbsp; แนะนำ &nbsp;
                                <i className='fad fa-gift' />
                            </div>
                        )}
                        <span>
                            <b>{pkg.cpu}</b> {makePlural('vCPU', pkg.cpu)}
                        </span>
                        <span>
                            Memory <b>{pkg.memory / 1024}</b> GB
                        </span>
                        <span>
                            SSD <b>{pkg.disk}</b> GB
                        </span>
                        <span>
                            Transfer <b>{pkg.bandwidth}</b> TB
                        </span>

                        <div className='xver-package-price'>
                            <div className='hourly'>
                                <small>ชั่วโมง</small>
                                <span>
                                    {format(pkg.price.hourly)} <small>฿</small>
                                </span>
                            </div>
                            <div className='monthly'>
                                <small>เดือน</small>
                                <span>
                                    {format(pkg.price.monthly, 0)}{' '}
                                    <small>฿</small>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default SelectPackage
