import { parse } from "querystring";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import { chargeService } from "../services/charge";
import { requestAuthUser } from "../store/auth/auth.actions";

interface WaitingContentProps {
    qr: string | undefined;
}

const WaitingContent: FC<WaitingContentProps> = ({ qr }) => (
    <div className="xver-payment-view waiting">
        <span>
            <i className="fad fa-spinner-third fa-spin" />
        </span>
        <h1>รอการชำระเงิน</h1>
        <h6>ระบบกำลังตรวจสอบรายการชำระเงิน</h6>
        {qr && (
            <div className="text-center promptpay-box">
                <img src={qr} alt="Promptpay" />
                <h6>** สแกน QR Code ด้วยแอพธนาคารเพื่อชำระเงิน **</h6>
            </div>
        )}
        <Link to="/billing" className="btn btn-default mt-1 min-w">
            ย้อนกลับ
        </Link>
    </div>
);

interface CompleteContentState {
    message?: string;
}

export const CompleteContent = () => {
    const location = useLocation<CompleteContentState>();

    return (
        <div className="xver-payment-view complete">
            <span>
                <i className="fad fa-check-circle" />
            </span>
            <h1>รายการสำเร็จ</h1>
            <h6>
                {location.state && location.state.message
                    ? location.state.message
                    : "ระบบได้ตรวจสอบรายการชำระเงินเรียบร้อยแล้ว"}
            </h6>
            <Link to="/billing" className="btn btn-default mt-1 min-w">
                ย้อนกลับ
            </Link>
        </div>
    );
};
const FailedContent = () => (
    <div className="xver-payment-view error">
        <span>
            <i className="fad fa-exclamation-triangle" />
        </span>
        <h1>รายการไม่สำเร็จ</h1>
        <h6>คุณได้ยกเลิกรายการชำระเงิน หรือไม่ชำระเงินภายในเวลาที่กำหนด</h6>
        <Link to="/billing" className="btn btn-default mt-1 min-w">
            ย้อนกลับ
        </Link>
    </div>
);

interface ErorrContentProps {
    error: string;
}

const ErorrContent: FC<ErorrContentProps> = ({ error }) => (
    <div className="xver-payment-view error">
        <span>
            <i className="fad fa-exclamation-triangle" />
        </span>
        <h1>เกิดข้อผิดพลาด</h1>
        <h6>{error}</h6>
        <Link to="/billing" className="btn btn-default mt-1 min-w">
            ย้อนกลับ
        </Link>
    </div>
);

interface BillingStatusParams {
    sourceID: string;
}

export const BillingStatus = () => {
    const params = useParams<BillingStatusParams>();
    const query = parse(useHistory().location.search.substring(1));

    const [refreshInterval, setRefreshInterval] = useState(5000);
    const { data, error } = useSWR(
        `/v1/charges/${params.sourceID}`,
        () => chargeService.getChargeInfo(params.sourceID),
        {
            refreshInterval: refreshInterval,
        }
    );

    const dispatch = useDispatch();
    useEffect(() => {
        if (data) {
            if (data.status === "successful") {
                dispatch(requestAuthUser());
            }
            if (data.status !== "pending") {
                setRefreshInterval(0);
            }
        }
    }, [data, dispatch]);

    if (error) {
        return <ErorrContent error={error.message} />;
    }
    if (!data || data.status === "pending") {
        return (
            <WaitingContent
                qr={query.qr ? atob(query.qr as string) : undefined}
            />
        );
    }
    if (data.status === "successful") {
        return <CompleteContent />;
    }
    if (data.status === "failed") {
        return <FailedContent />;
    }
    return <ErorrContent error={data.status} />;
};

export default BillingStatus;
