import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";
import { User } from "src/@types/user";
import OSIcon from "src/components/Instances/OSIcon";
import { Instance } from "../../@types/instance";
import { getOSFullName } from "../../utils/os";
import { makePlural } from "../../utils/string";
import CopyButton from "../ControlInstance/OverviewTab/CopyButton";

const renderInstanceListAlert = (user: User, instances: Instance[]) => {
    if (user.status === "insufficient_funds") {
        return (
            <div className="toast toast-error">
                <i className="fad fa-exclamation-triangle" />
                <span>
                    บัญชีของคุณถูกระงับการใช้งานเนื่องจากยอดคงเหลือไม่เพียงพอ
                    กรุณาเติมเงินให้เพียงพอต่อการใช้งานเพื่อปลดล็อคบัญชีของคุณ
                    โดยเซิร์ฟเวอร์จะถูกคิดเงินตามปกติในขณะที่ปิดอยู่เพื่อรักษาข้อมูล&nbsp;
                    <b>
                        หากคุณไม่ดำเนินการชำระยอดคงค้างภายใน 3 วัน{" "}
                        <small>(นับจากวันที่ถูกระงับ)</small>&nbsp;
                        เซิร์ฟเวอร์ทั้งหมดจะถูกลบและไม่สามารถกู้คืนได้
                    </b>
                </span>
            </div>
        );
    }
    if (instances.filter((ins) => ins.state.power === "install").length > 0) {
        return (
            <div className="toast toast-default">
                <i className="fad fa-info-circle" /> การสร้างเซิร์ฟเวอร์ใช้เวลา
                5 ถึง 10 นาทีโดยประมาณ
            </div>
        );
    }
    if (instances.filter((ins) => ins.state.power === "stopped").length > 0) {
        return (
            <div className="toast toast-default">
                <i className="fad fa-exclamation-triangle" />{" "}
                เซิร์ฟเวอร์ของคุณที่ปิดอยู่จะถูกคิดเงินตามปกติ
                จนกว่าเซิร์ฟเวอร์จะถูกลบ
            </div>
        );
    }
    return null;
};

interface InstanceListProps {
    user: User;
    instances: Instance[];
}
const InstanceList: FC<InstanceListProps> = ({ user, instances }) => (
    <div className="xver-instance-list-wrapper">
        {renderInstanceListAlert(user, instances)}

        {instances.length <= 0 ? (
            <div className="xver-box no-instance">
                <div className="box-content text-center">
                    <span className="fa-stack fa-2x">
                        <i className="fas fa-server fa-stack-1x" />
                        <i
                            className="fas fa-ban fa-stack-2x"
                            style={{ color: "Tomato" }}
                        />
                    </span>
                    <h2>ไม่มีเซิร์ฟเวอร์</h2>
                    <h6>ดูเหมือนว่าคุณยังไม่ได้สร้างเซิร์ฟเวอร์เลย</h6>
                    <Link to="/deploy" className="btn btn-primary">
                        สร้างเซิร์ฟเวอร์
                    </Link>
                </div>
            </div>
        ) : (
            instances.map((ins) => (
                <div className="xver-instance-list-item" key={ins.id}>
                    <div className="xver-instance-info">
                        <div className="instance-icon">
                            <div
                                className={clsx(
                                    "instance-icon-spinner",
                                    ins.state.power
                                )}
                            >
                                <OSIcon slug={ins.image.os} />
                            </div>
                        </div>
                        <span>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',

                                    fontWeight: 700
                                }}
                            >
                                {ins.hostname}
                                &nbsp;—&nbsp;<CopyButton textToCopy={ins.network.ipAddress} label={ins.network.ipAddress} fontBold />
                            </div>
                            <h6>{getOSFullName(ins.image.slug)}</h6>
                            <h6>
                                <b>{ins.spec.cpu}</b>{" "}
                                {makePlural("vCPU", ins.spec.cpu)} /&nbsp;
                                <b>{ins.spec.memory / 1024}</b> GB /{" "}
                                <b>{ins.spec.disk}</b> GB
                            </h6>
                        </span>
                    </div>
                    <div className="xver-instance-action">
                        {!["install", "destroying"].includes(
                            ins.state.power
                        ) && (
                            <Link
                                to={`/cloud-servers/${ins.id}`}
                                className="btn btn-primary btn-action"
                            >
                                <i className="fa fa-server" />
                            </Link>
                        )}
                    </div>
                </div>
            ))
        )}
    </div>
);

export default InstanceList;
