import { Notify } from 'src/@types/notify'

export const notifications: Notify[] = [
    {
        id: 1,
        label: 'test',
        icon: <i className="fas fa-exclamation-circle" />,
        title: 'ประกาศ',
        level: 'warning',
        content: <span>อัปเกรดความเร็วเครือข่ายเป็น 10 GB</span>,
    },
    // {
    //     id: 2,
    //     label: "freecoupon",
    //     icon: <i className="fas fa-exclamation-circle" />,
    //     title: "คูปองชดเชย",
    //     level: "typical",
    //     content: <span>รหัสคูปอง GF20 มูลค่า 20 บาท</span>,
    //     button: (
    //         <Link
    //             to="/billing/coupon"
    //             className="xver-btn"
    //             style={{
    //                 color: "#ffffff",
    //             }}
    //             onClick={() => {
    //                 localStorage.setItem("coupon", "GF20");
    //                 localStorage.setItem("coupon_value", "20");
    //             }}
    //         >
    //             ใช้งาน
    //         </Link>
    //     ),
    // },
]
