import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import AppLayout from './components/Layout/AppLayout'
// import KYCNoticeDialog from "./components/Middlewares/KYCNoticeDialog";
import { authService } from './services/auth'
import { ApplicationState } from './store'
import { setAuthUser } from './store/auth/auth.actions'
import Account from './views/Account'
import Billing from './views/Billing'
import BillingForm from './views/BillingForm'
import { BillingStatus, CompleteContent } from './views/BillingStatus'
import ControlInstance from './views/ControlInstance'
import DeployNewCloudServer from './views/DeployNewCloudServer'
import CloudServers from './views/CloudServers'
import SignOut from './views/SignOut'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { setUser, reset } from './services/chatwoot'
import DedicatedServers from './views/DedicatedServers'
import ControlBaremetalInstance from './views/ControlBaremetalInstance'
import DeployNewDedicatedServer from './views/DeployNewDedicatedServer'

declare global {
    interface Window {
        clarity: (action: any, tag: any, value: any) => void
    }
}
const App = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const authState = useSelector((state: ApplicationState) => state.auth)
    const initAuth = useCallback(async () => {
        try {
            const user = await authService.getUserInfo()
            window.clarity('set', 'email', user.email)
            dispatch(setAuthUser(user))

            // if (!window.$chatwoot) {
            //     window.addEventListener('chatwoot:ready', async () => {
            //         setUser(user)
            //     })
            // } else {
            //     setUser(user)
            // }
        } catch (e) {
            try {
                reset()
            } catch (error) {
                console.error(error)
            }

            localStorage.removeItem('x-auth')

            dispatch(setAuthUser(undefined))
            history.push('/sign-in')

            console.error(e)
        }
    }, [dispatch, history])

    useEffect(() => {
        initAuth()
    }, [initAuth])

    if (!authState.user) {
        return (
            <div className="xver-loading-splash">
                <div className="loading xver-loading" />
            </div>
        )
    }
    return (
        <AppLayout user={authState.user}>
            {/* <KYCNoticeDialog> */}
            <Switch>
                <Route exact path="/deploy" component={DeployNewCloudServer} />
                <Route exact path="/cloud-servers" component={CloudServers} />
                <Route
                    exact
                    path="/cloud-servers/:id"
                    component={ControlInstance}
                />
                <Route exact path="/deploy/dedicated-servers" component={DeployNewDedicatedServer} />
                <Route exact path="/dedicated-servers" component={DedicatedServers} />
                <Route
                    exact
                    path={"/dedicated-servers/:id"}
                    component={ControlBaremetalInstance}
                />

                <Route exact path="/billing">
                    <Billing tabName="billing" />
                </Route>
                <Route exact path="/billing/:tabName" component={Billing} />
                <Route exact path="/billing/form" component={BillingForm} />
                <Route
                    exact
                    path="/billing/status/complete"
                    component={CompleteContent}
                />
                <Route
                    exact
                    path="/billing/status/:sourceID"
                    component={BillingStatus}
                />

                <Route exact path="/account" component={Account} />
                <Route exact path="/sign-out" component={SignOut} />

                <Redirect to="/cloud-servers" />
            </Switch>
            {/* </KYCNoticeDialog> */}
        </AppLayout>
    )
}

export default App
