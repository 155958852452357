import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import InstanceList from '../components/Instances/InstanceList'
import QuotaSection from '../components/Instances/QuotaSection'
import { instanceService } from '../services/instance'
import { quotaService } from '../services/quota'
import { ApplicationState } from '../store'
import Notifications from 'src/components/Notifications/Notifications'
import { notifications } from 'src/data/notifications'

const CloudServers = () => {
    const authState = useSelector((state: ApplicationState) => state.auth)
    const { data: instances } = useSWR(
        '/v1/instances',
        instanceService.getInstances,
        {
            refreshInterval: 3000,
        }
    )
    const { data: quotas } = useSWR('/v1/quotas', quotaService.getQuotas)

    if (!authState.user) {
        return null
    }

    return (
        <div className="block">
            <div className="header-with-element">
                <h2 className="mb-0">
                    <i className="fas fa-cloud" />
                    &nbsp;คลาวด์เซิร์ฟเวอร์
                </h2>

                <Link
                    to="/deploy"
                    className="btn btn-primary"
                    style={{ fontSize: '16px' }}
                >
                    <i className="fas fa-plus"></i>
                    &nbsp;&nbsp;สร้างคลาวด์เซิร์ฟเวอร์
                </Link>
            </div>
            <div className="xver-content">
                <div className="columns">
                    <div className="column col-7 col-sm-12">
                        {instances ? (
                            <InstanceList
                                user={authState.user}
                                instances={instances.sort((a, b) =>
                                    a.id > b.id ? 1 : -1
                                )}
                            />
                        ) : (
                            <div className="loading xver-loading" />
                        )}
                    </div>
                    <div className="column col-5 col-sm-12">
                        {quotas ? (
                            <QuotaSection
                                user={authState.user}
                                quotas={quotas}
                            />
                        ) : (
                            <div className="loading xver-loading" />
                        )}
                        <Notifications notifies={notifications} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CloudServers
