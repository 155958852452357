export const makePlural = (word: string, v: number, suffix?: string) =>
	v > 1 ? word + (suffix ?? 's') : word

export const randomString = (n: number) => {
	let result = ''
	const chars =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
	for (let i = 0; i < n; i++) {
		result += chars.charAt(Math.floor(Math.random() * chars.length))
	}
	return result
}
