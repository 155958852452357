import clsx from 'clsx'
import { FC } from 'react'
import OSIcon from 'src/components/Instances/OSIcon'
import { LaunchOS } from '../../@types/launch'

interface SelectOSProps {
	data: LaunchOS[]

	selected: string
	select: (os: string, image: string) => void
}
const SelectOS: FC<SelectOSProps> = ({ data, selected, select }) => (
	<div className="xver-os-wrapper">
		{data.map((os) => (
			<div
				key={os.slug}
				className={clsx('xver-os', {
					active: os.versions.find((x) => x.slug === selected),
				})}
			>
				<div className="os-content">
					<OSIcon slug={os.slug} />
					<h6>{os.name}</h6>
				</div>
				<div className="os-footer">
					<select
						className="form-select"
						value={selected}
						onChange={(e) => {
							select(os.slug, e.currentTarget.value)
						}}
					>
						<option>เลือกเวอร์ชั่น</option>
						{os.versions.map((v) => (
							<option value={v.slug} key={v.slug}>
								{v.name}
							</option>
						))}
					</select>
				</div>
			</div>
		))}
	</div>
)

export default SelectOS
