import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LaunchBaremetalInstanceData } from "src/@types/launch";
import DedicatedServerSetting from "src/components/Deploy/DedicatedServerSetting";
import SelectDedicatedPackage from "src/components/Deploy/SelectDedicatedPackage";
import SelectOS from "src/components/Deploy/SelectOS";
import { instanceService } from "src/services/instance";
import { requestAuthUser } from "src/store/auth/auth.actions";
import useSWR from "swr";

export default function DeployNewDedicatedServer(): Readonly<React.ReactElement | null> {
    const { data: launchOptions } = useSWR('/v1/launch/baremetal', instanceService.getBaremetalLanuchOptions)

    const history = useHistory()
    const dispatch = useDispatch()

    const { values, isSubmitting, setFieldValue, handleChange, handleSubmit } = useFormik<LaunchBaremetalInstanceData>({
		initialValues: {
            package_id: 1,

			os_name: 'windows-server',
			os_image: 'win-2016',

			hostname: '',
            total_ip_addresses: 1
		},
		onSubmit: async (values) => {
			if (values.os_name === '' || values.os_image === '' || values.os_image === 'เลือกเวอร์ชั่น') {
				toast.error('กรุณาเลือกเวอร์ชั่นให้ถูกต้อง')
				return false
			}

			try {
				const res = await instanceService.lanuchBaremetal(values)

				dispatch(requestAuthUser());
				history.push('/dedicated-servers');
				toast.success(res.message);
			} catch ({ message }: string | any) {
				toast.error(message as string)
			}
		},
	});

    return (
        <div className="block">
			<div className="header-with-element">
				<div>
					<h2 className="mb-0">
                        <i className="fad fa-server" />
                        &nbsp;สร้างเซิร์ฟเวอร์ส่วนตัวใหม่
                    </h2>
				</div>

				<Link to="/dedicated-servers" className="btn btn-primary btn-action">
					<i className="fas fa-arrow-left" />
				</Link>
			</div>
			{launchOptions ? (
				<div className="xver-content">
					<SelectOS
						data={launchOptions.os}
						selected={values.os_image}
						select={(os_name: string, os_image: string) => {
							setFieldValue('os_name', os_name)
							setFieldValue('os_image', os_image)
						}}
					/>
					{/* Windows Server license warning */}
					{values.os_name === 'windows-server' && (
						<div className="toast toast-default">
							<i className="fad fa-exclamation-circle" /> No Windows License included.
						</div>
					)}

					<div className="mt-1">
                        <SelectDedicatedPackage
                            data={launchOptions.packages}

                            selected={values.package_id}
                            select={(v: number) => setFieldValue('package_id', v)}
                        />
					</div>
					<div className="columns mt-1">
						<div className="column col-6 col-sm-12">
							<div className="xver-box">
								<div className="box-header">
									<h2>Server Setting</h2>
									<h6>ตั้งค่าเซิร์ฟเวอร์</h6>
								</div>
								<div className="box-content">
									<DedicatedServerSetting
										loading={isSubmitting}

										hostname={values.hostname}
                                        totalOfIpAddresses={values.total_ip_addresses}

										handleChange={handleChange}
										handleSubmit={handleSubmit}
									/>
								</div>
							</div>
							<div style={{
								color: "yellow"
							}}
								className="text-warning text-small"
							>
								<i className="fad fa-engine-warning text-warning" />
								&nbsp; เมื่อกดสร้างเซิร์ฟเวอร์จะทำการตัดเงินตามราคาให้บริการรายเดือน
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className="loading xver-loading" />
			)}
		</div>
    );
}
