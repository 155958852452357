/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";
import { Instance } from "../@types/instance";
import BaseButton from "../components/Base/BaseButton";
import DestroyConfirmationModal from "../components/ControlInstance/DestroyConfirmationModal";
import OverviewTab from "../components/ControlInstance/OverviewTab/OverviewTab";
import { instanceService } from "../services/instance";
import clsx from "clsx";

interface ControlInstanceParams {
    id: string;
}
const ControlInstance = () => {
    const history = useHistory();
    const params = useParams<ControlInstanceParams>();
    const { data: instance, error } = useSWR(
        `/v1/cloud-servers/${params.id}`,
        () => instanceService.getInstance(params.id),
        {
            refreshInterval: 3000,
        }
    );

    useEffect(() => {
        if (error && error.message) {
            toast.error(error.message);
            history.push("/cloud-servers");
        }
    }, [error, history]);

    const [deleting, setDeleting] = useState(false);
    const [_deleteInstance, setDeleteInstance] = useState<Instance | undefined>(
        undefined
    );

    const destroyInstance = async () => {
        if (deleting) {
            return true;
        }

        setDeleting(true);
        try {
            const res = await instanceService.destroy(_deleteInstance?.id);

            toast.success(res.message);

            setDeleting(false);
            setDeleteInstance(undefined);
            history.push("/cloud-servers");
        } catch ({ message }: any) {
            toast.error(message as string);

            setDeleting(false);
        }
    };

    const [InstanceTab, SetInstanceTab] = useState<string>("controller");

    if (!instance) {
        return null;
    }

    return (
        <div className="block">
            <div className="header-with-element">
                <div>
                    <h2 className="mb-0">{instance?.hostname || "..."}</h2>
                    <h6 className="mb-0">
                        {instance?.network.ipAddress || "..."}
                    </h6>
                </div>

                <div
                    style={{
                        width: 'auto'
                    }}
                >
                    <BaseButton
                        color="error"
                        classes="btn btn-action mr-2"
                        loading={deleting}
                        style={{
                            width: '140px',
                        }}
                        onClick={() => setDeleteInstance(instance)}
                    >
                        <i className="fad fa-trash-alt"></i>
                        &nbsp;
                        <span>ลบเซิร์ฟเวอร์</span>
                    </BaseButton>

                    <Link
                        to="/cloud-servers"
                        className="btn btn-primary btn-action"
                    >
                        <i className="fas fa-arrow-left" />
                    </Link>
                </div>
            </div>
            <ul className="tab tab-block">
                <li
                    className={clsx("tab-item", {
                        active: InstanceTab === "controller",
                    })}
                >
                    <a
                        href="#"
                        onClick={() => SetInstanceTab("controller")}
                        style={{ cursor: "pointer" }}
                    >
                        แผงควบคุม
                    </a>
                </li>
                {/* <li
                    className={clsx("tab-item", {
                        active: InstanceTab === "backup",
                    })}
                >
                    <a
                        href="#"
                        onClick={() => SetInstanceTab("backup")}
                        style={{ cursor: "pointer" }}
                    >
                        สำรองข้อมูล
                    </a>
                </li> */}
            </ul>
            {instance ? (
                <div className="xver-content">
                    {InstanceTab === "controller" && (
                        <OverviewTab data={instance} />
                    )}
                    {InstanceTab === "backup" && (
                        <>
                           .
                        </>
                    )}
                </div>
            ) : (
                <div className="loading xver-loading" />
            )}

            <DestroyConfirmationModal
                loading={deleting}
                data={_deleteInstance}
                onConfirm={() => destroyInstance()}
                onClose={() => setDeleteInstance(undefined)}
            />
        </div>
    );
};

export default ControlInstance;
