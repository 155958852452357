import React from 'react'

const TermOfService = () => (
	<div className="auth-body">
		<div className="container">
			<div className="columns">
				<div className="column col-5 col-md-12 col-mx-auto mt-2">
					<div className="card">
						<div className="card-body">
							<h3 className="text-center">นโยบายคืนเงิน</h3>
							<br />

							<h5>
								<strong>XVER.CLOUD</strong>&nbsp;
								<strong>จะทำการคืนเงินให้แก่ลูกค้า กรณีดังต่อไปนี้</strong>
							</h5>

							<ul>
								<li>
									ไม่สามารถให้บริการตามที่ลูกค้าได้&nbsp;
									<b>คืนให้เต็มจำนวนไม่หักค่าใช้จ่าย</b>
								</li>
								<li>
									เมื่อลูกค้าเติมเงินเข้ามาแล้ว&nbsp;
									<b>ไม่สามารถคืนเงินได้</b>
								</li>
							</ul>

							<h5>
								<strong>ระยะเวลาการคืนเงิน</strong>
							</h5>

							<p>
								กรณีลูกค้าชำระเงินช่องทางตัดผ่านบัตรเครดิต
								จะทำการคืนเงินกลับไปยังบัตรเครดิตที่ลูกค้าใช้ในการชำระเงิน
								โดยใช้ระยะเวลาประมาณ 15-20 วันทำการ
								ขึ้นอยู่กับผู้ให้บริการทางการเงิน
							</p>

							<h5>
								<strong>นโยบายการยกเลิก</strong>
							</h5>

							<dl>
								<dt>กรณีที่สามารถยกเลิกการสั่งซื้อได้หรือไม่ได้?</dt>
								<dd>
									ลูกค้าไม่สามารถยกเลิกคำสั่งซื้อได้ เมื่อมีการชำระเงินเข้ามาแล้ว
								</dd>
							</dl>
							<dl>
								<dt>
									ลูกค้าต้องการยกเลิกจะต้องแจ้งล่วงหน้าเป็นเวลานานเท่าไหร่จึงจะสามารถขอคืนเงินได้
									?
								</dt>
								<dd>
									ลูกค้าไม่สามารถยกเลิกคำสั่งซื้อได้ เมื่อมีการชำระเงินเข้ามาแล้ว
								</dd>
							</dl>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default TermOfService
