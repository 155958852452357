/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import classNames from "classnames";
import clsx from "clsx";
import { useFormik } from "formik";
import React, { FC, Fragment, Suspense, useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { Address } from "src/@types/address";
import BaseSelect from "src/components/Base/BaseSelect";
import useSWR from "swr";
import { setTimeout } from "timers";
import { UpdateBillingRequest } from "../@types/bill";
import BaseInput from "../components/Base/BaseInput";
import BillingInfo from "../components/Billing/BillingInfo";
import Card from "../components/Billing/Card";
import Coupon from "../components/Billing/Coupon";
import InternetBank from "../components/Billing/InternetBank";
import Promptpay from "../components/Billing/Promptpay";
import Truewallet from "../components/Billing/Truewallet";
import { billService } from "../services/bill";
import { requestAuthUser } from "../store/auth/auth.actions";
import addressJson from "./../assets/json/address.json";

const HistoryBill = React.lazy(
    () => import("../components/Billing/HistoryBilling")
);

interface BillingMethodProps {
    tab: string;
    setTab: (tab: string) => void;
}

const BillingMethod: FC<BillingMethodProps> = ({ tab, setTab }) => (
    <Fragment>
        <div
            className={classNames("xver-tab-menu", {
                active: tab === "promptpay",
            })}
            onClick={() => setTab("promptpay")}
        >
            <i className="fad fa-qrcode" />
            <div>
                <h1>
                    PromptPay <small>(QR Code)</small>
                </h1>
                <h6>พร้อมเพย์</h6>
            </div>
        </div>
        <div
            className={classNames("xver-tab-menu", {
                active: tab === "truewallet",
            })}
            onClick={() => setTab("truewallet")}
        >
            <i className="fad fa-wallet" />
            <div>
                <h1>TrueMoney Wallet</h1>
                <h6>ทรูมันนี่วอลเล็ท </h6>
            </div>
        </div>
        <div
            className={classNames("xver-tab-menu", {
                active: tab === "internet-bank",
            })}
            onClick={() => setTab("internet-bank")}
        >
            <i className="fad fa-university" />
            <div>
                <h1>Internet Banking</h1>
                <h6>อินเทอร์เน็ตแบงก์กิ้ง</h6>
            </div>
        </div>
        <div
            className={classNames("xver-tab-menu", {
                active: tab === "credit-card",
            })}
            onClick={() => setTab("credit-card")}
        >
            <i className="fad fa-credit-card" />
            <div>
                <h1>Credit Card</h1>
                <h6>บัตรเครดิต</h6>
            </div>
        </div>
        <div
            className={classNames("xver-tab-menu", {
                active: tab === "coupon",
            })}
            onClick={() => setTab("coupon")}
        >
            <i className="fas fa-gift" />
            <div>
                <h1>Coupon</h1>
                <h6>คูปองส่วนลด</h6>
            </div>
        </div>
    </Fragment>
);
interface BillingProps {
    tabName: string;
}

const Billing: FC<BillingProps> = ({ tabName }) => {
    const params = useParams<BillingProps>();
    if (params.tabName !== "") {
        tabName = params.tabName;
    }

    const START_AT_PAGE: number = 1;
    const PAGE_SIZE: number = 10;
    const recordPerPage: number = 3;
    let totalRecords: number = 0;
    const pageRange: number = 3;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [topTab, setTopTab] = useState<string>("billing");
    const [addresses] = useState<Address>(addressJson);
    const DEFAULT_PAGE_SIZE: number = 10;
    const dispatch = useDispatch();
    const history = useHistory();
    const [tab, setTab] = useState("promptpay");
    const { data: checkUnset } = useSWR(
        "/v1/billing/check-unset-billing",
        billService.unsetBilling
    );
    const { data: historyResponse } = useSWR(
        `/v1/billing/history?page=${START_AT_PAGE}&page_size=${PAGE_SIZE}`,
        () => billService.historyBill(START_AT_PAGE, PAGE_SIZE)
    );

    const { data: billingInfo } = useSWR("/v1/billing/billing", billService.billing);

    const [province, setProvince] = useState<string>("");
    const [district, setDistrict] = useState<string>("");
    const [subDistrict, setSubDistrict] = useState<string>("");

    const [districtState, setDistrictState] = useState<boolean>(true);
    const [subDistrictState, setSubDistrictState] = useState<boolean>(true);

    const [postalCode, setPostalCode] = useState<string>("");

    const DistrictOptions = addresses.map((provinceItem) => {
        if (provinceItem.name_th === province) {
            return provinceItem.amphure.map((district, index) => (
                <option key={index} value={district.name_th}>
                    {district.name_th}
                </option>
            ));
        }
    });

    const SubDistrictOptions = addresses.map((provinceItem) => {
        if (provinceItem.name_th === province) {
            return provinceItem.amphure.map((districtItem) => {
                if (districtItem.name_th === district) {
                    return districtItem.tambon.map((subDistrictItem, index) => (
                        <option key={index} value={subDistrictItem.name_th}>
                            {subDistrictItem.name_th}
                        </option>
                    ));
                }
            });
        }
    });

    function ProvinceOnChangeEventHandler(value: string) {
        if (value.length < 1) {
            setProvince("");
            setDistrict("");
            setSubDistrict("");

            setDistrictState(true);
            setSubDistrictState(true);
        } else {
            setProvince(value);
            setDistrict("");
            setSubDistrict("");

            setDistrictState(false);
            setSubDistrictState(true);
        }
    }

    function DistrictOnChangeEventHandler(value: string) {
        if (value.length < 1) {
            setDistrict("");
            setSubDistrictState(true);
        } else {
            setDistrict(value);
            setSubDistrictState(false);
        }
    }

    function SubDistrictOnChangeEventHandler(value: string) {
        if (value.length < 1) {
            setSubDistrict("");
        } else {
            setSubDistrict(value);
        }
    }

    function AddressConcatenator(value: string): string | undefined {
        if (value === "กรุงเทพมหานคร") {
            return `, แขวง ${district},  ${subDistrict}, ${province} (ประเทศไทย) ${postalCode}`;
        } else {
            return `, อำเภอ ${district}, ตำบล ${subDistrict}, จังหวัด ${province} (ประเทศไทย) ${postalCode}`;
        }
    }

    const pageCountFromAPI = historyResponse?.page_count as number;

    for (let record = 1; record <= pageCountFromAPI; record++) {
        if (pageCountFromAPI === 1) {
            break;
        }

        totalRecords += 2;
    }

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const billingResponse = useFormik<UpdateBillingRequest>({
        initialValues: {
            address: billingInfo?.address !== undefined ? billingInfo.address : "",
            company:  billingInfo?.company !== undefined ? billingInfo.company : "",
            tax_id:  billingInfo?.taxid !== undefined ? billingInfo.taxid : "",
        },
        onSubmit: async (values) => {
            if (province.length === 0) {
                toast.error("กรุณาใส่ชื่อจังหวัดหรือปกครองให้ถูกต้อง");
                return;
            }

            if (district.length === 0) {
                toast.error("กรุณาใส่ชื่ออำเภอหรือแขวงให้ถูกต้อง");
                return;
            }

            if (subDistrict.length === 0) {
                toast.error("กรุณาใส่ชื่อตำบลหรือให้ถูกต้อง");
                return;
            }

            if (postalCode.length === 0 || postalCode.length < 5) {
                toast.error("กรุณาใส่เลขรหัสไปรษณีย์ให้ถูกต้อง");
                return;
            }

            try {
                await billService.updateBilling({
                    address: values.address + AddressConcatenator(province),
                    company: values.company,
                    tax_id: values.tax_id,
                });

                dispatch(requestAuthUser());
                history.push("/billing");
                toast.success("แก้ไขรายละเอียดแล้ว");

                setTimeout(() => window.location.reload(), 1024);
            } catch ({ message }: any) {
                toast.error(message as string);
            }
        },
    });

    useEffect(() => {
        if (tabName === "history" && checkUnset?.status) {
            history.push("/billing/form");
        }
        if (tabName) {
            setTab(tabName);
            history.push("/billing/" + tabName);
        }
    }, [checkUnset, history, tabName]);

    return (
        <div className="block">
            <div className="header-with-element">
                <h2 className="mb-0">
                    <i className="fad fa-wallet" />
                    &nbsp;การเรียกเก็บเงิน
                </h2>
            </div>
            <ul className="tab tab-block">
                <li
                    className={clsx("tab-item", {
                        active: topTab === "billing",
                    })}
                >
                    <a
                        onClick={() => setTopTab("billing")}
                        style={{ cursor: "pointer" }}
                    >
                        เติมเงิน
                    </a>
                </li>
                <li
                    className={clsx("tab-item", {
                        active: topTab === "history",
                    })}
                >
                    <a
                        onClick={() => setTopTab("history")}
                        style={{ cursor: "pointer" }}
                    >
                        ประวัติ
                    </a>
                </li>
            </ul>
            <div className="xver-content">
                {topTab === "billing" && (
                    <div className="columns">
                        <div className="column col-4 col-sm-12">
                            <BillingMethod
                                tab={tab}
                                setTab={(v) => setTab(v)}
                            />
                        </div>
                        <div className="column col-7 col-sm-12">
                            {tab === "truewallet" && <Truewallet />}
                            {tab === "promptpay" && <Promptpay />}
                            {tab === "internet-bank" && <InternetBank />}
                            {tab === "credit-card" && <Card />}
                            {tab === "coupon" && <Coupon />}
                        </div>
                    </div>
                )}
                {topTab === "history" && (
                    <div className="billing-history columns ">
                        <div className="column col-12 ">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title h5">
                                        Billing Settings
                                    </div>
                                    <div className="card-subtitle text-gray">
                                        ตั้งค่าธุรกิจและที่อยู่ของบิล
                                    </div>
                                </div>
                                <BillingInfo info={billingInfo} />
                                <div className="card-footer">
                                    <div className="modal" id="billing-info">
                                        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                        <a
                                            className="modal-overlay"
                                            href="#billing-info-closed"
                                            aria-label="Close"
                                        />
                                        <div
                                            className="modal-container"
                                            role="document"
                                        >
                                            <div className="modal-header">
                                                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                                                <a
                                                    className="btn btn-clear float-right"
                                                    href="#billing-info-closed"
                                                    aria-label="Close"
                                                ></a>
                                                <div className="modal-title h5">
                                                    แก้ไขรายละเอียดการเรียกเก็บเงิน
                                                </div>
                                            </div>
                                            <div className="modal-body body-margin-top-form">
                                                <div className="content">
                                                    <form
                                                        autoComplete="off"
                                                        className="width-95"
                                                        onSubmit={
                                                            billingResponse.handleSubmit
                                                        }
                                                    >
                                                        <div className="columns">
                                                            <div className="column col-6">
                                                                <BaseInput
                                                                    type="text"
                                                                    name="company"
                                                                    label="ชื่อบริษัทหรือองค์กรของคุณ"
                                                                    maxLength={
                                                                        64
                                                                    }
                                                                    onChange={
                                                                        billingResponse.handleChange
                                                                    }
                                                                    defaultValue={billingInfo?.company}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="column col-6">
                                                                <BaseInput
                                                                    type="text"
                                                                    name="tax_id"
                                                                    label="เลขประจำตัวของผู้เสียภาษี"
                                                                    maxLength={
                                                                        13
                                                                    }
                                                                    required
                                                                    onChange={
                                                                        billingResponse.handleChange
                                                                    }
                                                                    defaultValue={billingInfo?.taxid}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/* Address */}
                                                        <BaseInput
                                                            type="text"
                                                            name="address"
                                                            label="ที่อยู่ของผู้เสียภาษี"
                                                            required
                                                            onChange={
                                                                billingResponse.handleChange
                                                            }
                                                            defaultValue={billingInfo?.address}
                                                        />

                                                        <div className="columns">
                                                            <div className="column col-6">
                                                                <BaseSelect
                                                                    name="province"
                                                                    label="จังหวัดหรือการปกครองพิเศษ (คลิกเพื่อเลือก)"
                                                                    value={
                                                                        province
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        ProvinceOnChangeEventHandler(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }
                                                                    >
                                                                        กรุณาเลือกพื้นที่จังหวัด
                                                                    </option>
                                                                    {addresses.map(
                                                                        (
                                                                            province,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        province.name_th
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </BaseSelect>
                                                            </div>
                                                            <div className="column col-6">
                                                                <BaseSelect
                                                                    name="district"
                                                                    label="อำเภอหรือแขวง (คลิกเพื่อเลือก)"
                                                                    value={
                                                                        district
                                                                    }
                                                                    disabled={
                                                                        districtState
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        DistrictOnChangeEventHandler(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }
                                                                    >
                                                                        {province
                                                                            ? "กรุณาเลือกพื้นที่อำเภอ"
                                                                            : "กำลังรอการเลือกพื้นที่จังหวัด"}
                                                                    </option>
                                                                    {
                                                                        DistrictOptions
                                                                    }
                                                                </BaseSelect>
                                                            </div>
                                                        </div>
                                                        <div className="columns">
                                                            <div className="column col-6">
                                                                <BaseSelect
                                                                    name="sub-district"
                                                                    label="ตำบลหรือ (คลิกเพื่อเลือก)"
                                                                    value={
                                                                        subDistrict
                                                                    }
                                                                    disabled={
                                                                        subDistrictState
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        SubDistrictOnChangeEventHandler(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    <option
                                                                        value={
                                                                            ""
                                                                        }
                                                                    >
                                                                        {district
                                                                            ? "กรุณาเลือกพื้นที่ตำบล"
                                                                            : "กำลังรอการเลือกพื้นที่อำเภอ"}
                                                                    </option>
                                                                    {
                                                                        SubDistrictOptions
                                                                    }
                                                                </BaseSelect>
                                                            </div>
                                                            <div className="column col-6">
                                                                <BaseInput
                                                                    type="text"
                                                                    name="postal-code"
                                                                    label="รหัสไปรษณีย์"
                                                                    maxLength={
                                                                        5
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setPostalCode(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="col-6 btn btn-primary btn-billing-form text-white"
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <a
                                                                href="#billing-info-closed"
                                                                style={{
                                                                    color: "white",
                                                                    textDecoration:
                                                                        "none",
                                                                }}
                                                            >
                                                                บันทึกรายละเอียด
                                                            </a>
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <div className="form-group form-kyc-info">
                                                    เมื่อบันทึกข้อมูล ไม่มีผลต่อ{" "}
                                                    <strong>
                                                        ใบการเรียกเก็บเงิน
                                                    </strong>
                                                    ก่อนหน้านี้
                                                    หากต้องการแก้ไขข้อมูล
                                                    <strong>
                                                        ใบการเรียกเก็บเงิน
                                                    </strong>
                                                    ก่อนหน้านี้กรุณาติดต่อ
                                                    support@xver.cloud
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a
                                        type="button"
                                        className="column col-2 col-sm-4 btn btn-md btn-info"
                                        // eslint-disable-next-line
                                        href="#billing-info"
                                    >
                                        <i className="fad fa-pen"></i>
                                        &nbsp;แก้ไข
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="column col-12">
                            {billingInfo?.address && billingInfo?.company && billingInfo.taxid ? (
                                <Suspense fallback={<span>กำลังดึงข้อมูล</span>}>
                                    <HistoryBill
                                        page={currentPage}
                                        pageSize={DEFAULT_PAGE_SIZE}
                                    />
                                </Suspense>
                            ) : (
                                <span>กรุณาใส่ข้อมูลให้ครบถ้วนก่อนทำการออกใบเสร็จ</span>
                            )}
                            {historyResponse?.data.length === 0 ? (
                                <></>
                            ) : (
                                <div className="column col-12 clearfix">
                                    <Pagination
                                        innerClass="pagination"
                                        itemClass="page-item"
                                        activePage={currentPage}
                                        itemsCountPerPage={recordPerPage}
                                        totalItemsCount={totalRecords}
                                        pageRangeDisplayed={pageRange}
                                        onChange={handlePageChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Billing;
