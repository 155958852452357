import classNames from 'classnames'
import React from 'react'
import { XVerBaremetalPackage } from 'src/@types/launch'
import { format } from 'src/utils/number'

interface SelectDedicatedPacakgeProps {
    data: XVerBaremetalPackage[]

    selected: number
    select: (v: number) => void
}

export default function SelectDedicatedPackage(
    props: SelectDedicatedPacakgeProps
): Readonly<React.ReactElement<SelectDedicatedPacakgeProps> | null> {
    return (
        <section style={{ position: 'relative' }}>
            <div className="xver-dedicated-package-wrapper">
                {props.data.map((pkg) => {
                    return (
                        <div
                            key={pkg.id}
                            className={classNames('xver-package', {
                                active: pkg.id === props.selected,
                                recommended:
                                    pkg.recommended &&
                                    pkg.id !== props.selected,
                            })}
                            onClick={() => props.select(pkg.id)}
                        >
                            {pkg.recommended && (
                                <div className="xver-recommended">
                                    <i className="fad fa-gift" />
                                    &nbsp; แนะนำ &nbsp;
                                    <i className="fad fa-gift" />
                                </div>
                            )}
                            <div>
                                <span>CPU</span>
                                <b>{pkg.cpu_label}</b>
                            </div>
                            <span>
                                Memory <b>{pkg.ram_label}</b>
                            </span>
                            <span>
                                Network <b>{pkg.network_traffic_label}</b>
                            </span>
                            <span>
                                Disk <b>{pkg.ssd_size_of_mb / 1000} GB</b> SSD
                            </span>

                            <div className="xver-package-price">
                                <div className="monthly">
                                    <small>เดือน</small>
                                    <span>
                                        {format(pkg.price_monthly, 0)}{' '}
                                        <small>฿</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}
