import React, { ChangeEvent, FormEvent } from "react";
import BaseInput from "../Base/BaseInput";
import BaseButton from "../Base/BaseButton";
import BaseTermOfService from "../Base/BaseTermOfService";

interface DedicatedServerSettingProps {
    loading: boolean;

    hostname: string;
    totalOfIpAddresses: number;

	handleChange: (e: ChangeEvent<any>) => void
	handleSubmit: (e: FormEvent<any>) => void
}

export default function DedicatedServerSetting(props: DedicatedServerSettingProps): Readonly<React.ReactElement<DedicatedServerSettingProps> | null> {
    const [agree, setAgree] = React.useState<boolean>(false);

    return (
        <form autoComplete="off" onSubmit={props.handleSubmit}>
            <BaseInput
				type="number"
				name="IP Addresses"
				label="IP Addresses"
                defaultValue={props.totalOfIpAddresses}
                onChange={props.handleChange}
				required
			/>

            <BaseInput
				type="text"
				name="hostname"
				label="Hostname"
				required
				defaultValue={props.hostname}
				onChange={props.handleChange}
			/>

            <div className="form-group">
				<BaseButton
					type="submit"
					classes="btn-lg btn-block"
					loading={props.loading}
					disabled={!agree}
				>
					เริ่มต้นการสร้างเซิร์ฟเวอร์
				</BaseButton>
			</div>

			<div className='form-group'>
				<BaseTermOfService
					to='/term-of-service'
					custom_text1='เมื่อสร้างเครื่องแล้ว '
					custom_text2='หากทำการปิดเครื่องจะมีการคิดเงินต่อจนกว่าจะดำเนินการลบไฟล์'
					state={[agree, setAgree]}
				/>
			</div>
        </form>
    );
}
